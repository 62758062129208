<section id='home' class='container'>
    <div class='row justify-content-center mt-5'>
        <div class='col-sm-8 col-md-6 col-lg-4'>
            <img class='img-fluid' src='./assets/img/OneSuite_stack_rgb.svg' />
        </div>
    </div>

    <div class='row justify-content-center'>
        <div class='col-sm-12 col-md-8 text-center border-bottom mt-4 mb-4 pb-4 px-5'>
            <h4 class='mx-sm-2 mx-mb-5'>OneSuite of applications, integrated directly with your ERP, providing you with a myriad of functionality to help you with your day to day business needs.</h4>
        </div>
    </div>

    <div class='row mb-5'>
        <div class='col text-center'>
            <a mdbBtn size='lg' rounded='true' color='primary' href='mailto:keith.webster@terracor.ca'><mdb-icon fas icon='at'></mdb-icon> Contact Us for a Demo</a>
        </div>
    </div>

    <div class='row mb-3 rounded mx-0 one-ship-container'>
        <div class='col-sm-12 col-md-3 px-0'>
            <img class='img-fluid' src='./assets/img/OneShip_solid.svg' />
        </div>
        <div class='col-sm-12 col-md-9 my-3 border-left px-4'>
            <h2 class='text-hide'>OneShip</h2>
            <div>
                Our new integrated shipping app allows you to save time and money by easily comparing rates among your shipping providers and instantly updating orders in Spire. Take control of your shipping costs with <strong>OneShip</strong>.
            </div>
            <hr />
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Integrated order data
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Pull weight and dimensions directly from your ERP
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Compare rates between UPS, FedEx, Canada Post & Purolator (more coming soon!)
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Easily download shipping labels and push rates to your ERP
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    An easy to use app that works for all ERP orders, no other software required!
                </div>
            </div>
        </div>
    </div>

    <div class='row mb-3 rounded mx-0 one-account-container'>
        <div class='col-sm-12 col-md-3 px-0'>
            <img class='img-fluid' src='./assets/img/OneAccount_solid.svg' />
        </div>
        <div class='col-sm-12 col-md-9 my-3 border-left px-4'>
            <h2 class='text-hide'>OneAccount</h2>
            <div>
                The all-new <strong>OneAccount</strong> is about to make managing invoices and customer history easier than ever. You no longer have to dedicate valuable resources to reprinting invoices, looking up old orders, and supplying account information to your customers, clients, or sales reps.  Our integrated sales portal pulls live order data in an easy to use portal accessible to your customers and employees.
            </div>
            <hr />
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Allow customers and sales reps to easily create their own account and begin using the app
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Our direct integration means your web portal is always up to date!
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    View open and historical order data
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    View account balances and credit limits
                </div>
            </div>
        </div>
    </div>

    <div class='row mb-3 rounded mx-0 one-stock-container'>
        <div class='col-sm-12 col-md-3 px-0'>
            <img class='img-fluid' src='./assets/img/OneStock_solid.svg' />
        </div>
        <div class='col-sm-12 col-md-9 my-3 border-left px-4'>
            <h2 class='text-hide'>OneStock</h2>
            <div>
                Easily find inventory, locations, and product details from your desktop, tablet or phone with <strong>OneStock</strong>. No more expensive licenses, desktop tools or heavyweight systems are required for store staff to easily find, move, or query an item in your inventory.
            </div>
            <hr />
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Pull up-to-date data with a live integration
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Find stock by scanning, entering the part-number, or SKU
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Easily view the price, on-hand stock levels, warehouse location, product descriptions, and more!
                </div>
            </div>
        </div>
    </div>

    <!-- <div class='mt-4 mb-3 py-2 light-blue darken-2 text-center text-white font-weight-bolder h3'>
        Coming Soon
    </div>

    <div class='row mb-3 rounded mx-0 one-catalog-container'>
        <div class='col-sm-12 col-md-3 px-0'>
            <img class='img-fluid' src='./assets/img/OneCatalog_solid.svg' />
        </div>
        <div class='col-sm-12 col-md-9 my-3 border-left px-4'>
            <h2 class='text-hide'>OneCatalog</h2>
            <div>
                Easily generate an eye-catching catalog using data in your accounting software. No more expensive designers or long catalog creation processes. With <strong>OneCatalog</strong> you can instantly create a catalog with the categories, product information, and price levels already in your accounting software.
            </div>
            <hr />
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Generate simple price lists or rich detailed catalogs
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Filter your products by multiple attributes including category
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Set custom page headers & footers
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Customer specific catalogs containing their pricing
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Automatic table of contents generation with custom front & back cover pages
                </div>
            </div>
        </div>
    </div>

    <div class='row mb-3 rounded mx-0 one-reward-container'>
        <div class='col-sm-12 col-md-3 px-0'>
            <img class='img-fluid' src='./assets/img/OneReward_solid.svg' />
        </div>
        <div class='col-sm-12 col-md-9 my-3 border-left px-4'>
            <h2 class='text-hide'>OneReward</h2>
            <div>
                Finally, an integrated reward system, because your clients deserve a reward for all of their purchases. Provide points, offer promotions and coupons, and offer a web portal for your clients to view their rewards status. <strong>OneReward</strong>, integrated rewards for all of your customers.
            </div>
            <hr />
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Generate simple price lists or rich detailed catalogs
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Filter your products by multiple attributes including category
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Set custom page headers & footers
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    Customer specific catalogs containing their pricing
                </div>
            </div>
            <div class='d-flex mb-1'>
                <div class='pr-2'><i class='fas fa-check-square'></i></div>
                <div class='flex-fill'>
                    utomatic table of contents generation with custom front & back cover pages
                </div>
            </div>
        </div>
    </div> -->
</section>
