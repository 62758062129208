<style>
    table.table-sm td, table.table-sm th {
        padding-top: 3rem
    }
</style>

<app-company></app-company>

<mdb-card *ngIf='data.selectedCompanyId' data-cy='inventory-filters' class='animated fadeIn mb-2'>
    <mdb-card-body>
        <h4 class='h4-responsive' data-cy='transfer-filters-title'>Transfer filters</h4>

        <div class='row'>
            <div class='col-6'>
                <label>Transferring from</label>
                <app-warehouses (selectedWarehouse)='setFromWarehouse($event)' [warehouse]='this.fromWarehouse' data-cy='from-warehouse'></app-warehouses>
            </div>

            <div class='col-6'>
                <label>Transferring to</label>
                <app-warehouses (selectedWarehouse)='setToWarehouse($event)' [warehouse]='this.toWarehouse' data-cy='to-warehouse'></app-warehouses>
            </div>
        </div>

        <hr />

        <div class='row'>
            <div class='col-12'>
                <label>Product code</label>
                <app-product-categories (selectedCategory)='setProductCategoryFilter($event)' data-cy='product-category-filter'></app-product-categories>
            </div>
        </div>
    </mdb-card-body>
    <mdb-card-footer class='text-right'>
        <button mdbBtn [disabled]='!areWarehousesSelected()' (click)='runInventorySearch()' color='primary' title='Run Report' data-cy='inventory-search-button'>
            <mdb-icon *ngIf='!searchingInventory' fas icon='search'></mdb-icon>
            <div *ngIf='searchingInventory' class='spinner-border spinner-border-sm' role='status'></div>
        </button>
    </mdb-card-footer>
</mdb-card>

<mdb-card *ngIf='data.selectedCompanyId && products' data-cy='inventory-table' class='animated fadeIn mb-2'>
    <mdb-card-body>
        <table id='warehouse-transfer-table' mdbTable data-cy='inventory-transfer-table' hover='true' small='true' stickyHeader='true'>
            <thead>
                <tr>
                    <th>Wh-Item #</th>
                    <th>Description</th>
                    <th class='text-center'>'{{this.toWarehouse}}' Reorder Point</th>
                    <th class='text-center'>'{{this.toWarehouse}}' On Hand</th>
                    <th class='text-center'>'{{this.fromWarehouse}}' Available</th>
                    <th class='text-center'>'{{this.toWarehouse}}' Suggested Transfer</th>
                    <th class='text-center'>Picked</th>
                    <th class='text-center'>Verified</th>
                </tr>
            </thead>
            <tbody>
                <tr mdbTableCol *ngFor='let product of products' [hidden]='product.processed' [ngClass]='product.error ? "table-danger" : ""'>
                    <td>{{this.toWarehouse}}-{{product.number}}</td>
                    <td>{{product.description}}</td>
                    <td class='text-center'>{{getToWarehouseQuantity(product, 'reorderQuantity')}}</td>
                    <td class='text-center'>{{getToWarehouseQuantity(product, 'quantityOnHand')}}</td>
                    <td class='text-center'>{{getFromWarehouseAvailableQuantity(product)}}</td>
                    <td class='text-center'><input type='number' [(ngModel)]='product.transferQty' class='form-control text-center'/></td>
                    <td class='text-center'><mdb-checkbox [(ngModel)]='product.picked'></mdb-checkbox></td>
                    <td class='text-center'><mdb-checkbox [(ngModel)]='product.verified'></mdb-checkbox></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <button mdbBtn (click)='print()' color='primary' title='Print'>
                            <mdb-icon fas icon='print'></mdb-icon>
                        </button>
                    </td>

                    <td>
                        <button mdbBtn [disabled]='processing' (click)='process()' color='primary' title='Process' data-cy='process-button'>
                            <mdb-icon *ngIf='!processing' fas icon='arrow-right'></mdb-icon>
                            <div *ngIf='processing' class='spinner-border spinner-border-sm' role='status'></div>
                        </button>
                    </td>
                </tr>
            </tfoot>
        </table>
    </mdb-card-body>
</mdb-card>

<table id='warehouse-transfer-report' data-cy='inventory-transfer-report' class='d-none' style='width: 100%'>
    <thead>
        <tr><th colspan='2' style='text-align: left'>From: {{this.fromWarehouse}}</th></tr>
        <tr><th colspan='2' style='text-align: left'>To: {{this.toWarehouse}}</th></tr>
        <tr><th colspan='2' style='text-align: left'>Date processed: {{today | date:'fullDate'}}</th></tr>
        <tr>
            <th style='text-align: center; border: 1px solid; border-bottom: none; width: 10%'></th>
            <th style='text-align: left; border: 1px solid; border-bottom: none; width: 50%'></th>
            <th style='text-align: center; border: 1px solid; border-bottom: none;'>'{{this.toWarehouse}}'</th>
            <th style='text-align: center; border: 1px solid; border-bottom: none;'>'{{this.toWarehouse}}'</th>
            <th style='text-align: center; border: 1px solid; border-bottom: none;'>'{{this.fromWarehouse}}'</th>
            <th style='text-align: center; border: 1px solid; border-bottom: none;'>'{{this.toWarehouse}}'</th>
            <th style='text-align: center; border: 1px solid; border-bottom: none;'></th>
            <th style='text-align: center; border: 1px solid; border-bottom: none;'></th>
        </tr>
        <tr>
            <th style='text-align: center; border: 1px solid; border-top: none; width: 10%'>Item #</th>
            <th style='text-align: left; border: 1px solid; border-top: none; width: 50%'>Description</th>
            <th style='text-align: center; border: 1px solid; border-top: none;'>Reorder Point</th>
            <th style='text-align: center; border: 1px solid; border-top: none;'>On Hand</th>
            <th style='text-align: center; border: 1px solid; border-top: none;'>Available</th>
            <th style='text-align: center; border: 1px solid; border-top: none;'>Transfer Qty</th>
            <th style='text-align: center; border: 1px solid; border-top: none;'>Picked</th>
            <th style='text-align: center; border: 1px solid; border-top: none;'>Verified</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor='let product of products'>
            <td style='text-align: center; border: 1px solid'>{{product.number}}</td>
            <td style='text-align: left; border: 1px solid'>{{product.description}}</td>
            <td style='text-align: center; border: 1px solid'>{{getToWarehouseQuantity(product, 'reorderQuantity')}}</td>
            <td style='text-align: center; border: 1px solid'>{{getToWarehouseQuantity(product, 'quantityOnHand')}}</td>
            <td style='text-align: center; border: 1px solid'>{{getFromWarehouseAvailableQuantity(product)}}</td>
            <td style='text-align: center; border: 1px solid'>{{product.transferQty}}</td>
            <td style='text-align: center; border: 1px solid'>{{product.picked === true ? 'YES' : ''}}</td>
            <td style='text-align: center; border: 1px solid'>{{product.verified === true ? 'YES' : ''}}</td>
        </tr>
    </tbody>
</table>
