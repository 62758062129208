/**
 * @prettier
 */

import { AppDataService } from '../services/app-data.service'
import { Component } from '@angular/core'
import * as _ from 'lodash'

import { ToastService } from 'ng-uikit-pro-standard'
import { UserProfileService } from '../services/user-profile.service'
import { ApiService } from '../services/api.service'
import { query } from '@angular/animations'
import { ProductCategory } from '../product-categories/product-categories.component'
import { Warehouse } from '../warehouses/warehouses.component'

interface Product {
    readonly number: string
    readonly buyUnit: string
    readonly category: string
    readonly description: string
    readonly links: object
    readonly sellUnit: string
    readonly unitOfMeasure: string
    readonly userDefinedFields: object
    error: string
    processed: boolean
    transferQty: number
    warehouses: Warehouse[]
}

interface QueryParams {
    limit?: number
    page?: number
    fromWarehouseFilter?: string
    toWarehouseFilter?: string
    productCategoryFilter?: string
}

@Component({
    selector: 'app-warehouse-transfer',
    templateUrl: './warehouse-transfer.component.html',
})
export class WarehouseTransferComponent {
    public products: Product[]
    public categories: ProductCategory[]
    public today: Date = new Date()
    public processing: boolean
    public searchingInventory: boolean
    public fromWarehouse: string
    public toWarehouse: string
    public selectedFromWarehouse: string
    public selectedToWarehouse: string
    public productCategoryFilter: ProductCategory

    private pageLimit = 10000

    public constructor(public data: AppDataService, private toast: ToastService, private user: UserProfileService, private api: ApiService) {
        this.data.currentAppName = 'OneTransfer'

        this.selectedFromWarehouse = this.getSettings().defaultFromWarehouse
        this.fromWarehouse = this.selectedFromWarehouse
    }

    public setFromWarehouse(warehouse) {
        this.selectedFromWarehouse = warehouse.number
    }

    public setToWarehouse(warehouse) {
        if (this.selectedFromWarehouse === warehouse.number) {
            this.showErrorToast('The transfer to warehouse cannot be the same as the from')
            this.toWarehouse = undefined
        }

        this.selectedToWarehouse = warehouse.number
    }

    public setProductCategoryFilter(category) {
        this.productCategoryFilter = category
    }

    public areWarehousesSelected() {
        return !_.isUndefined(this.selectedFromWarehouse) && !_.isUndefined(this.selectedToWarehouse)
    }

    public getToWarehouseQuantity(product: Product, type: string) {
        const warehouse = _.find(product.warehouses, { warehouse: this.toWarehouse })

        if (!_.isUndefined(warehouse)) {
            return warehouse[type]
        }
    }

    public getFromWarehouseAvailableQuantity(product: Product) {
        const warehouse = _.find(product.warehouses, { warehouse: this.fromWarehouse })

        if (!_.isUndefined(warehouse)) {
            return warehouse.quantityOnHand - warehouse.quantityCommitted - warehouse.minimumOrderQuantity
        }
    }

    public async runInventorySearch() {
        this.searchingInventory = true
        this.fromWarehouse = this.selectedFromWarehouse
        this.toWarehouse = this.selectedToWarehouse

        let currentPage = 1
        let results = await this.getResults(currentPage)

        let filtered = this.filterResults(results.items)
        this.products = _.orderBy(filtered, [p => p.description.toLowerCase()])

        const pages = Math.ceil(results.itemCount / this.pageLimit)
        if (pages > 1) {
            while (currentPage < pages) {
                results = await this.getResults(++currentPage)
                filtered = this.filterResults(results.items)
                _.forEach(filtered, (item: Product) => {
                    this.products.push(item)
                })
            }
        }

        this.searchingInventory = false
    }

    public getSuggestedTransferQty(item) {
        const warehouse = _.find(item.warehouses, { warehouse: this.toWarehouse })
        const suggestedQuantity = Math.ceil(warehouse.reorderQuantity - warehouse.quantityAvailable)

        return suggestedQuantity > 0 ? suggestedQuantity : 0
    }

    public print() {
        const content = document.getElementById('warehouse-transfer-report').outerHTML
        const body = '<html><body>' + content + '</body></html>'

        const iframe = document.createElement('iframe')
        const contents = new Blob([body], { type: 'text/html' })

        iframe.src = URL.createObjectURL(contents)
        document.body.appendChild(iframe)
        iframe.style.visibility = 'hidden'
        iframe.contentWindow.print()
    }

    public process() {
        if (!confirm('Are you sure you want to process this warehouse transfer?')) {
            return
        }

        _.forEach(this.products, (product) => {
            if (product.transferQty > 0 && !product.processed) {
                this.processing = true
                product.error = ''

                const params = {
                    PartNumber: product.number,
                    SourceWarehouse: this.fromWarehouse,
                    TargetWarehouse: this.toWarehouse,
                    TransferQuantity: product.transferQty
                };

                this.api.transferInventory(params).subscribe(
                    (res) => {
                        this.processing = false
                        product.processed = true
                    },
                    (err) => {
                        this.processing = false
                        product.error = 'Error transferring inventory for ' + product.number + ': ' + err.error
                        this.showErrorToast(product.error)
                    }
                )
            }
        })
    }

    private filterResults(results) {
        const validItems = []
        _.forEach(results, (item) => {
            if (this.toWarehouseValid(item) && this.availableQuantityValid(item)) {
                item.transferQty = Math.min(this.getSuggestedTransferQty(item), this.getFromWarehouseAvailableQuantity(item))
                validItems.push(item)
            }
        })

        return validItems
    }

    private toWarehouseValid(item) {
        const warehouse = _.find(item.warehouses, { warehouse: this.toWarehouse })

        return warehouse.reorderQuantity > 0 && warehouse.reorderQuantity > warehouse.quantityAvailable
    }

    private availableQuantityValid(item) {
        const warehouse = _.find(item.warehouses, { warehouse: this.fromWarehouse })

        return Math.ceil(warehouse.quantityOnHand - warehouse.quantityCommitted - warehouse.minimumOrderQuantity) > 0
    }

    private getResults(page: number) {
        const rawParams: QueryParams = { limit: this.pageLimit, page, toWarehouseFilter: this.toWarehouse, fromWarehouseFilter: this.fromWarehouse }
        if (this.productCategoryFilter && this.productCategoryFilter.number) {
            rawParams.productCategoryFilter = this.productCategoryFilter.number
        }

        return this.api.fetchProducts(this.buildQueryString(rawParams)).toPromise()
    }

    private buildQueryString(rawParams: QueryParams) {
        const params = []

        if (rawParams.limit) {
            params.push(`limit=${rawParams.limit}`)
        }

        if (rawParams.page) {
            params.push(`page=${rawParams.page}`)
        }

        if (rawParams.toWarehouseFilter) {
            params.push(`toWarehouseFilter=${rawParams.toWarehouseFilter}`)
        }

        if (rawParams.fromWarehouseFilter) {
            params.push(`fromWarehouseFilter=${rawParams.fromWarehouseFilter}`)
        }

        if (rawParams.productCategoryFilter) {
            params.push(`productCategoryFilter=${rawParams.productCategoryFilter}`)
        }

        let queryString = ''
        if (params.length > 0) {
            queryString = '?' + params.join('&')
        }

        return queryString
    }

    private getSettings() {
        return this.user.getWarehouseTransferSettings()
    }

    private showErrorToast(message) {
        this.toast.error(message, '', { opacity: 1 })
    }
}
