import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NbAuthService, NbAuthSimpleToken } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';

import * as _ from 'lodash';

@Injectable()
export class RoleProvider implements NbRoleProvider {

    constructor(private authService: NbAuthService) {
    }

    getRole(): Observable<string> {
        return this.authService.onTokenChange()
            .pipe(
                map((token: NbAuthSimpleToken) => {
                    let role = 'guest';
                    if (token.isValid()) {
                        const acl = _.get(token.getValue(), 'acl', {});

                        if (!_.isEmpty(acl)) {
                            role = acl.role;
                        }
                    }

                    return role;
                }),
            );
    }
}
