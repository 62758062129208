<div *ngIf='trackingInfo && trackingInfo.activity'>
    <table mdbTable hover='true' small='true'>
        <thead class='sticky-top'>
            <tr>
                <th colspan='5' class='text-center'>Tracking #: {{ trackingInfo.trackingNumber }}</th>
            </tr>

            <tr>
                <th>Description</th>
                <th>Status</th>
                <th>Date & Time</th>
                <th>Signatory</th>
                <th>Location</th>
            </tr>
        </thead>

        <tbody>
            <tr mdbTableCol *ngFor='let activity of trackingInfo.activity'>
                <td>{{ activity.description }}</td>
                <td>{{ activity.statusDescription }} ({{ activity.statusCode }})</td>
                <td>{{ activity.dateTime }}</td>
                <td>{{ activity.signatory }}</td>
                <td>{{ activity.location.city }} {{ activity.location.division }} {{ activity.location.country }}
                    {{ activity.location.postalCode }}</td>
            </tr>
        </tbody>
    </table>
</div>
