<header>
    <mdb-side-nav #sidenav [fixed]='false' class='wide sn-bg-1 fixed light-blue darken-2'>
        <links>
            <li>
                <ul class='collapsible collapsible-accordion' data-cy='sidenav'>
                    <li data-cy='home-menu-item'>
                        <a (click)='sidenav.hide()' routerLink=''>
                            <mdb-icon fas icon='home'></mdb-icon> Home
                        </a>
                    </li>

                    <li *ngIf='auth.authenticated()' data-cy='dashboard-menu-item'>
                        <a (click)='sidenav.hide()' routerLink='dashboard'>
                            <mdb-icon fas icon='chart-line'></mdb-icon> Dashboard
                        </a>
                    </li>
                    <li *ngIf='isAccountAppEnabled()' data-cy='account-menu-item'>
                        <a (click)='sidenav.hide()' routerLink='account'>
                            <mdb-icon fas icon='user-circle'></mdb-icon> Account
                        </a>
                    </li>
                    <li *ngIf='isStockAppEnabled()' data-cy='stock-menu-item'>
                        <a (click)='sidenav.hide()' routerLink='stock'>
                            <mdb-icon fas icon='warehouse'></mdb-icon> Stock
                        </a>
                    </li>
                    <li *ngIf='isShippingAppEnabled()' data-cy='shipping-menu-item'>
                        <a (click)='sidenav.hide()' routerLink='shipping'>
                            <mdb-icon fas icon='shipping-fast'></mdb-icon> Shipping
                        </a>
                    </li>
                    <li *ngIf='isWarehouseTransferAppEnabled()' data-cy='warehouse-transfer-menu-item'>
                        <a (click)='sidenav.hide()' routerLink='warehouse-transfer'>
                            <mdb-icon fas icon='dolly'></mdb-icon> Transfer
                        </a>
                    </li>

                    <li *ngIf='auth.authenticated()' data-cy='log-menu-item'>
                        <a (click)='openLogDialog(sidenav)'>
                            <mdb-icon far icon='eye'></mdb-icon> Log
                        </a>
                    </li>
                </ul>
            </li>
        </links>
        <div class='sidenav-bg mask-strong'></div>
    </mdb-side-nav>

    <mdb-navbar SideClass='navbar fixed-top navbar-toggleable-md navbar-expand-lg scrolling-navbar double-nav light-blue darken-2' [containerInside]='false'>
        <navlinks class='navbar-container'>
            <div>
                <a (click)='sidenav.show()' class='button-collapse hidden-nav-button-collapse mx-0' data-cy='app-menu'>
                    <mdb-icon fas icon='bars'></mdb-icon>
                </a>
            </div>
        </navlinks>

        <mdb-navbar-brand>
            <span data-cy='title'>{{currentAppName()}}</span>
        </mdb-navbar-brand>

        <navlinks>
            <ul class='nav navbar-nav nav-flex-icons ml-auto ie-double-nav ie-hidden-double-nav'>
                <li class='nav-item dropdown btn-group' dropdown>
                    <a dropdownToggle type='button' class='nav-link dropdown-toggle waves-light' data-cy='user-profile'>
                        <mdb-icon fas icon='user'></mdb-icon>
                        <span *ngIf='auth.authenticated()' data-cy='username'>{{auth.user?.name}}</span>
                    </a>
                    <div class='dropdown-menu dropdown-primary dropdown-menu-right' role='menu'>
                        <section *ngIf='auth.authenticated()' class='p-3'>
                            <h5 data-cy='user-email'>{{getEmail()}}</h5>

                            <div *ngIf='isActiveTrial()' data-cy='trial-info'>Your {{currentAppName()}} trial expires on {{getTrialExpiry()}}</div>

                            <hr/>

                            <button mdbBtn class='float-right' color='primary' (click)='auth.logout()' *ngIf='auth.authenticated()' data-cy='logout-menu-item'>
                                <mdb-icon fas icon="sign-out-alt"></mdb-icon> Logout
                            </button>
                        </section>

                        <a class='dropdown-item' (click)='auth.login()' *ngIf='!auth.authenticated()' data-cy='login-menu-item'>
                            <mdb-icon fas icon='sign-in-alt'></mdb-icon> Sign in
                        </a>

                        <a class='dropdown-item' (click)='auth.register()' *ngIf='!auth.authenticated()' data-cy='register-menu-item'>
                            <mdb-icon fas icon='user-circle'></mdb-icon> Register
                        </a>
                    </div>
                </li>
            </ul>
        </navlinks>
    </mdb-navbar>
</header>

<main>
    <div class='container mt-2'>
        <router-outlet></router-outlet>
    </div>
</main>

<footer class='light-blue darken-2 p-3 mt-5'>
    <div class="small text-white text-center">
        THE TERRACOR NETWORK
    </div>
    <div class='d-flex justify-content-center flex-column flex-md-row text-center text-white'>
        <div>
            <a href='https://www.zeckoshop.com' class='text-white mx-3' target='_blank'>zeckoShop</a>
        </div>
        <div>|</div>
        <div>
            <a href='https://www.terracor.ca' class='text-white mx-3' target='_blank'>Terracor</a>
        </div>
        <div>|</div>
        <div>
            <a href='https://www.terracor.ca/marketplaces' class='text-white mx-3' target='_blank'>Marketplaces</a>
        </div>
    </div>
    <div class='text-center small text-white mt-3'>
        &#169;2020 Terracor Business Solutions Inc.
    </div>
</footer>
