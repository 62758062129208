<div data-cy='modal'>
    <div data-cy='modal-header' class='modal-header'>
        <button type='button' class='close pull-right' aria-label='Close' (click)='modalRef.hide()'>
            <span aria-hidden='true'>×</span>
        </button>
        <h4 class='modal-title w-100'>Set your ERP credentials</h4>
    </div>
    <div data-cy='modal-body' class='modal-body mat-elevation-z8'>
        <div class='md-form mb-5'>
            <mdb-icon fas icon='user' class='prefix grey-text'></mdb-icon>
            <input [(ngModel)]='username' data-cy='username-input' type='text' class='form-control' mdbInput required>
            <label>Your username</label>
        </div>

        <div class='md-form mb-4'>
            <mdb-icon fas icon='lock' class='prefix grey-text'></mdb-icon>
            <input [(ngModel)]='password' data-cy='password-input' type='password' class='form-control' mdbInput required>
            <label>Your password</label>
        </div>
    </div>
    <div class='modal-footer'>
        <div *ngIf='errorMessage' data-cy='modal-error' class='alert alert-danger'>
            {{errorMessage}}
        </div>

        <button [disabled]='!valid()' (click)='updateCredentials()' data-cy='update' mdbBtn color='success'>
            Update
            <div *ngIf='updating' class='spinner-border spinner-border-sm' role='status'></div>
        </button>

        <button mdbBtn color='primary' (click)='modalRef.hide()'>
            <mdb-icon fas icon='close'></mdb-icon> Close
        </button>
    </div>
</div>
