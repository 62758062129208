import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
    selector: 'app-trial-expired-dialog',
    templateUrl: './trial-expired-dialog-component.html'
})
export class TrialExpiredDialogComponent {

    public app;

    public constructor(public modalRef: MDBModalRef, private router: Router) {}

    public goHome() {
        this.router.navigate(['/']);
        this.modalRef.hide();
    }
}
