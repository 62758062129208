import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { forkJoin, of, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import * as _ from 'lodash'
import { environment } from '../../environments/environment'
import { UserProfileService } from './user-profile.service'

@Injectable()
export class HttpService {

    private headers: HttpHeaders

    public constructor(private http: HttpClient, private userProfile: UserProfileService) {
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Api-Key': userProfile.getApiKey()
        })
    }

    public get(route, additionalHeaders = {}): Observable<any> {
        return this.request('GET', route, {}, additionalHeaders)
    }

    public put(route, params, additionalHeaders = {}): Observable<any> {
        return this.request('PUT', route, { body: params }, additionalHeaders)
    }

    public post(route, params, additionalHeaders = {}): Observable<any> {
        return this.request('POST', route, { body: params }, additionalHeaders)
    }

    public multiRequest(requests) {
        const self = this
        requests = _.map(requests, (request: any) => {
            return self.post(request.url, request.body).pipe(
                map(res => request.successCallback ? request.successCallback(res) : res)
            )
        })

        return this.forkRequests(requests)
    }

    public getPdf(route) {
        const headers = new HttpHeaders({'Api-Key': this.userProfile.getApiKey()})

        return this.http.get(
            environment.apiUrl + route,
            {
                responseType: 'arraybuffer',
                headers
            }
        )
    }

    private forkRequests(requests) {
        const self = this
        return forkJoin(requests, (...args) => {
            let results = []
            for (const argument of args) {
                results = _.concat(results, argument)
            }

            return results
        })
    }

    private request(method, url, options, additionalHeaders): Observable<any> {
        for (const key in additionalHeaders) {
            if (additionalHeaders.hasOwnProperty(key)) {
                this.headers = this.headers.set(key, additionalHeaders[key])
            }
        }

        options.headers = this.headers
        return this.http.request(method, environment.apiUrl + url, options)
    }
}
