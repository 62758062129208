import { Injectable } from '@angular/core'
import * as _ from 'lodash'

import { UserProfileService } from './user-profile.service'

@Injectable()
export class ShippingOrderTransformerService {
    public constructor(private userProfile: UserProfileService) {}

    public transform(order) {
        const origin = order.shippingOrigin
        const items = !_.isEmpty(order.boxes) ? order.boxes : order.items

        return {
            number: order.number,
            service: order.service,
            destName: order.shippingAddress.name,
            destAddress1: _.isEmpty(order.shippingAddress.line1) ? order.shippingAddress.line2 : order.shippingAddress.line1,
            destCode: order.shippingAddress.postalZip,
            destCity: order.shippingAddress.city,
            destDivisionCode: order.shippingAddress.region,
            destCountryCode: order.shippingAddress.country,
            destPhone: order.shippingAddress.phoneNumber,
            originCompany: origin.description,
            originAddress1: origin.address.line1,
            originCode: origin.address.postalCode,
            originCity: origin.address.city,
            originDivisionCode: origin.address.region,
            originCountryCode: origin.address.country,
            originPhone: origin.address.phoneNumber,
            items: this.buildItems(items)
        }
    }

    private buildItems(orderDetails) {
        return _.map(orderDetails, (orderDetail, idx: number) => {
            if (orderDetail.quantity > 0) {
                return {
                    packageId: 'P' + ++idx,
                    quantity: orderDetail.quantity,
                    weight: orderDetail.weight,
                    length: orderDetail.length,
                    width: orderDetail.width,
                    height: orderDetail.height
                }
            }
        })
    }
}
