import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { MDBBootstrapModulesPro, MDBSpinningPreloader } from 'ng-uikit-pro-standard'
import { NgxWebstorageModule } from 'ngx-webstorage'
import { RoutingModule } from './routing.module'
import { ToastModule } from 'ng-uikit-pro-standard'
// import { ZXingScannerModule } from '@zxing/ngx-scanner'
import { BoxService } from './services/box.service'

import { NbAlertModule, NbButtonModule, NbCardModule, NbDialogModule, NbSidebarModule, NbSidebarService, NbUserModule } from '@nebular/theme'

import { NbAuthModule, NbPasswordAuthStrategy, NbAuthSimpleToken, NbAuthComponent } from '@nebular/auth'

import { NbSecurityModule, NbRoleProvider } from '@nebular/security'

import { AppDataService } from './services/app-data.service'
import { AuthGuardService } from './services/auth-guard.service'
import { AuthService } from './services/auth.service'
import { ApiService } from './services/api.service'
import { HttpService } from './services/http.service'
import { LogService } from './services/log.service'
import { StockPresenterService } from './services/stock-presenter.service'
import { PageService } from './services/page.service'
import { ReportService } from './services/report.service'
import { ShippingOrderTransformerService } from './services/shipping-order-transformer.service'
import { ShippingService } from './services/shipping.service'
import { UserProfileService } from './services/user-profile.service'
import { CacheService } from './services/cache-service'

import { AppComponent } from './app.component'
import { AccountComponent } from './account/account.component'
import { BarcodeScannerComponent } from './barcode-scanner/barcode-scanner.component'
import { ClientBoxesComponent } from './shipping/boxes/client-boxes.component'
import { CompanyComponent } from './company/company.component'
import { DashboardComponent } from './dashboard/dashboard.component'
import { ErpCredentialsDialogComponent } from './erp-credentials-dialog/erp-credentials-dialog.component'
import { HomeComponent } from './home/home.component'
import { InvoiceDetailsDialogComponent } from './invoice-details-dialog/invoice-details-dialog.component'
import { LogDialogComponent } from './log-dialog/log-dialog.component'
import { OrderDetailsDialogComponent } from './order-details-dialog/order-details-dialog.component'
import { OrderLookupComponent } from './order-lookup/order-lookup.component'
import { ProductCategoriesComponent } from './product-categories/product-categories.component'
import { ShippingComponent } from './shipping/shipping.component'
import { ShippingCarriersComponent } from './shipping/carriers/carriers.component'
import { ShippingRatesComponent } from './shipping/rates/rates.component'
import { ShippingTrackingComponent } from './shipping/tracking/tracking.component'
import { StockComponent } from './stock/stock.component'
import { TrialExpiredDialogComponent } from './trial-expired-dialog/trial-expired-dialog-component'
import { WarehousesComponent } from './warehouses/warehouses.component'

import { environment } from '../environments/environment'
import { RoleProvider } from './services/role.provider';
import { WarehouseTransferComponent } from './warehouse-transfer/warehouse-transfer.component'

@NgModule({
    declarations: [
        AccountComponent,
        AppComponent,
        BarcodeScannerComponent,
        ClientBoxesComponent,
        CompanyComponent,
        DashboardComponent,
        ErpCredentialsDialogComponent,
        HomeComponent,
        InvoiceDetailsDialogComponent,
        LogDialogComponent,
        OrderDetailsDialogComponent,
        OrderLookupComponent,
        ProductCategoriesComponent,
        ShippingCarriersComponent,
        ShippingRatesComponent,
        ShippingTrackingComponent,
        ShippingComponent,
        StockComponent,
        TrialExpiredDialogComponent,
        WarehousesComponent,
        WarehouseTransferComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        RoutingModule,
        NgxWebstorageModule.forRoot(),
        MDBBootstrapModulesPro.forRoot(),
        NbAlertModule,
        NbButtonModule,
        NbCardModule,
        NbDialogModule.forRoot({}),
        NbSidebarModule,
        NbUserModule,
        NbAuthModule.forRoot({
            strategies: [
                NbPasswordAuthStrategy.setup({
                    name: 'email',
                    baseEndpoint: environment.apiUrl + 'auth/',
                    token: {
                        class: NbAuthSimpleToken,
                        key: 'profile',
                    },
                    register: {
                        redirect: {
                            success: '/',
                            failure: null,
                        },
                    },
                    requestPass: {
                        redirect: {
                            success: null,
                            failure: null,
                        },
                    },
                    resetPass: {
                        redirect: {
                            success: '/login',
                            failure: null,
                        },
                    },
                }),
            ],
            forms: {
                login: {
                    redirectDelay: 0,
                    showMessages: {
                        success: true,
                    },
                },
                register: {
                    redirectDelay: 0,
                    showMessages: {
                        success: true,
                    },
                    terms: false,
                },
                requestPassword: {
                    redirectDelay: 0,
                    showMessages: {
                        success: true,
                    },
                },
                resetPassword: {
                    redirectDelay: 100,
                    showMessages: {
                        success: true,
                    },
                },
                logout: {
                    redirectDelay: 0,
                },
            },
        }),
        NbSecurityModule.forRoot({
            accessControl: {
                user: {
                    view: ['customer-details'],
                },
                client: {
                    parent: 'user',
                    view: ['account-status'],
                },
                clientAdmin: {
                    parent: 'client',
                    view: ['customer-search'],
                },
                superAdmin: {
                    parent: 'clientAdmin',
                    view: '*',
                },
            },
        }),
        ToastModule.forRoot(),
        // ZXingScannerModule,
    ],
    providers: [
        AppDataService,
        AuthGuardService,
        AuthService,
        ApiService,
        HttpService,
        LogService,
        MDBSpinningPreloader,
        PageService,
        ReportService,
        ShippingOrderTransformerService,
        ShippingService,
        StockPresenterService,
        UserProfileService,
        BoxService,
        CacheService,
        { provide: NbRoleProvider, useClass: RoleProvider },
    ],
    entryComponents: [InvoiceDetailsDialogComponent, OrderDetailsDialogComponent, TrialExpiredDialogComponent, BarcodeScannerComponent],
    bootstrap: [AppComponent],
})
export class AppModule { }
