import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core'
import { Subject, Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import * as _ from 'lodash'
import { ApiService } from '../services/api.service'
import { CacheService } from '../services/cache-service';

const CACHE_KEY = 'warehouses'

export interface Warehouse {
    readonly warehouse: string
    readonly quantityAvailable: number
    readonly quantityBackordered: number
    readonly quantityCommitted: number
    readonly minimumOrderQuantity: number
    readonly quantityOnHand: number
    readonly quantityOnOrder: number
    readonly reorderQuantity: number
}

@Component({
    selector: 'app-warehouses',
    templateUrl: './warehouses.component.html',
})
export class WarehousesComponent implements OnInit, OnChanges {
    @Input() items: any
    @Input() warehouse: any
    @Output() selectedWarehouse = new EventEmitter()

    public searchText = new Subject()
    public filteredOptions: Observable<any[]>

    public warehouses: Warehouse[]

    public constructor(private apiService: ApiService, private cache: CacheService) {}

    ngOnInit() {
        this.fetchWarehouses()
    }

    private fetchWarehouses(): void {
        const warehousesCache = this.cache.getCacheItem(CACHE_KEY)

        if (Object.keys(warehousesCache.data).length === 0) {
            this.apiService.fetchWarehouses().subscribe((res) => {
                this.warehouses = res.items

                this.cache.setCacheItem(CACHE_KEY, this.warehouses)
                this.setFiltersAndWarehouse()
            })
        } else {
            this.warehouses = warehousesCache.data
            this.setFiltersAndWarehouse()
        }
    }

    ngOnChanges() {
        this.setWarehouse()
    }

    private setFiltersAndWarehouse() {
        this.filteredOptions = this.searchText.pipe(
            startWith(''),
            map((description: string) => this.filter(description))
        )

        this.setWarehouse()
    }

    private setWarehouse() {
        if (!_.isUndefined(this.warehouses)) {
            const defaultWarehouse = !_.isUndefined(this.items) && this.items.length > 0 ? this.items[0].warehouse : _.get(this.warehouse, 'number')
            if (defaultWarehouse) {
                this.warehouses.map((warehouse: any) => {
                    if (warehouse.number === defaultWarehouse) {
                        this.warehouse = warehouse.number
                        this.selectedWarehouse.emit(warehouse)
                    }
                })
            }
        }
    }

    public filter(value: string): any[] {
        const filterValue = value.toLowerCase()
        return this.warehouses.filter((option: any) => option.description.toLowerCase().includes(filterValue))
    }

    public warehouseSelected() {
        let newWarehouse: any
        this.warehouses.map((warehouse: any) => {
            if (warehouse.number === this.warehouse) {
                newWarehouse = warehouse
            }
        })

        this.selectedWarehouse.emit(newWarehouse)
    }
}
