<div data-cy='modal'>
    <div data-cy='modal-header' class='modal-header'>
        <button type='button' class='close pull-right' aria-label='Close' (click)='modalRef.hide()'>
            <span aria-hidden='true'>×</span>
        </button>
        <h4 class='modal-title w-100'>Log of the last 24 hours</h4>
    </div>
    <div *ngIf='!retrievingDetails && !errorMessage' data-cy='modal-body' class='modal-body mat-elevation-z8'>
        <table mdbTable *ngIf='events' hover='true' small='true'>
            <thead class="sticky-top">
                <tr>
                    <th>Request URI</th>
                    <th>Message</th>
                </tr>
            </thead>

            <tbody>
                <tr mdbTableCol *ngFor='let event of events'>
                    <td>{{event.requestedUri}}</td>
                    <td>{{event.message}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class='modal-footer'>
        <div *ngIf='retrievingDetails' class='alert alert-info'>
            Retrieving details...
        </div>

        <div *ngIf='errorMessage' data-cy='modal-error' class='alert alert-danger'>
            {{errorMessage}}
        </div>

        <button mdbBtn color='primary' (click)='modalRef.hide()'>
            <mdb-icon fas icon='close'></mdb-icon> Close
        </button>
    </div>
</div>
