<div data-cy='modal'>
    <div data-cy='modal-header' class='modal-header'>
        <h4 class='modal-title w-100'>Trial Expired</h4>
    </div>
    <div data-cy='modal-body' class='modal-body mat-elevation-z8'>
        <div class='row mb-5'>
            <div class='col text-center'>
                <p data-cy='one-account-trial' class='font-weight-bold'>Your {{app}} trial has ended</p>
            </div>
        </div>
        <div class='row mb-5'>
            <div class='col text-center'>
                <a mdbBtn color='primary' href='mailto:parker.lachance@terracor.ca?subject {{app}} Trial Expired!'>
                    <mdb-icon fas icon='at'></mdb-icon> Contact us for assistance!
                </a>
            </div>
        </div>
    </div>
    <div class='modal-footer'>
        <button mdbBtn color='primary' data-cy='home-button' (click)='goHome()'>
            <mdb-icon fas icon='home'></mdb-icon> Home
        </button>
    </div>
</div>
