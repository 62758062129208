import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
    selector: 'app-invoice-details-dialog',
    templateUrl: './invoice-details-dialog.component.html'
})
export class InvoiceDetailsDialogComponent implements OnInit {

    public errorMessage;
    public invoice;
    public retrievingDetails;

    public constructor(private apiService: ApiService, public modalRef: MDBModalRef) { }

    public ngOnInit() {
        this.retrievingDetails = true;
        this.apiService.fetchInvoiceByNumber(this.invoice.number).subscribe(
            invoice => {
                this.retrievingDetails = false;
                this.invoice = invoice;
            },
            err => {
                this.retrievingDetails = false;
                this.errorMessage = 'Error retrieving invoice: ' + err.error;
            }
        );
    }
}
