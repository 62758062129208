<mdb-card class='mb-2'>
    <mdb-card-body>
        <mdb-card-title><h5>Selected Company</h5></mdb-card-title>

        <div class='row'>
            <div class='col col-10'>
                <mdb-select-2 data-cy='company-select' placeholder='Select company' [(ngModel)]='data.selectedCompanyId'>
                    <mdb-select-option *ngFor='let company of companies' [value]='company.number'>{{ company.name }}</mdb-select-option>
                </mdb-select-2>
            </div>

            <div class='col'>
                <button mdbBtn color='primary' data-cy='update-credentials' (click)='openErpCredentialsDialog()' title='Set your ERP credentials'>
                    <mdb-icon fas icon='sign-in-alt'></mdb-icon>
                </button>
            </div>
        </div>
    </mdb-card-body>
</mdb-card>
