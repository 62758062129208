import { Injectable } from '@angular/core'

interface CacheData {
    data: any
    cleanupDate: number
}

const CACHE_EXPIRY = 1000 * 60 * 60 * 24;

@Injectable()
export class CacheService {
    public setCacheItem(key: string, value: any): void  {
        const item = {
            data: value,
            cleanupDate: new Date().getTime() + CACHE_EXPIRY
        }

        try {
            localStorage.setItem(key, JSON.stringify(item))
        } catch (e: any) {
            // log
        }
    }

    public getCacheItem(key: string) {
        let cacheItem = { data: [], cleanupDate: undefined } as CacheData

        try {
            const data = localStorage.getItem(key)

            if (data) {
                cacheItem = JSON.parse(data)
                if (cacheItem.cleanupDate < new Date().getTime()) {
                    cacheItem.data = []
                    cacheItem.cleanupDate = undefined
                }
            }
        } catch (e) {
            // log
        }

        return cacheItem
    }
}
