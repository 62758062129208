import { Component, OnInit } from '@angular/core'
import { ApiService } from '../services/api.service'
import { AppDataService } from '../services/app-data.service'
import { ErpCredentialsDialogComponent } from '../erp-credentials-dialog/erp-credentials-dialog.component'
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard'
import { ToastService } from 'ng-uikit-pro-standard'
import { UserProfileService } from '../services/user-profile.service'
import { CacheService } from '../services/cache-service'
import * as _ from 'lodash';

const CACHE_KEY = 'companies';

interface Company {
    readonly companyAddress: string
    readonly email: string
    readonly name: string
    readonly number: string
}

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
})
export class CompanyComponent implements OnInit {
    public companies = []
    public modalRef: MDBModalRef
    public retrievingData = false

    public constructor(
        public data: AppDataService,
        private api: ApiService,
        private userProfile: UserProfileService,
        private toast: ToastService,
        private modal: MDBModalService,
        private cache: CacheService
    ) {}

    public ngOnInit(): void {
        this.fetchCompanies()
    }

    public openErpCredentialsDialog() {
        this.modalRef = this.modal.show(ErpCredentialsDialogComponent, {
            class: 'modal-dialog modal-md',
            data: {},
        })

        this.modal.closed.subscribe(() => {
            this.fetchCompanies()
        })
    }

    private fetchCompanies() {
        const companiesCache = this.cache.getCacheItem(CACHE_KEY)
        if (Object.keys(companiesCache.data).length === 0) {
            this.api.fetchCompanies().subscribe(
                (companies) => {
                    this.retrievingData = false
                    this.setCompanyData(companies.items)
                    this.cache.setCacheItem(CACHE_KEY, this.companies)
                },
                (err) => {
                    this.retrievingData = false
                    this.companies = []
                    this.toast.error('Error retrieving companies: ' + err.message, '', { opacity: 1 })
                }
            )
        } else {
            this.setCompanyData(companiesCache.data)
        }
    }

    private setCompanyData(companies: Company[]) {
        this.companies = companies
        this.data.selectedCompanyId = this.userProfile.getErpSettings().defaultCompanyId ?? this.companies[0].number
    }
}
