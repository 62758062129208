<mdb-card [hidden]='!data.currentOrder.isLoaded()'>
    <mdb-card-body>
        <h4 class='h4-responsive'>Rates</h4>

        <app-shipping-carriers #shippingCarriers></app-shipping-carriers>

        <button mdbBtn [disabled]='!hasCarriers(shippingCarriers)' (click)='getRates(shippingCarriers.getSelectedCarriers())' id='retrieve-rates-button' data-cy='retrieve-rates-button' color='primary' title='Retrieve Rates'>
            <mdb-icon fas *ngIf='!retrievingData' icon='search'></mdb-icon>
            <div *ngIf='retrievingData' class='spinner-border spinner-border-sm' role='status'></div>
        </button>

        <button mdbBtn *ngIf='hasRates()' (click)='createShipment()' id='ship-button' data-cy='ship-button' class='float-right' color='warning' title='Create Shipment'>
            <mdb-icon fas *ngIf='!creatingShipment' icon='truck'></mdb-icon>
            <div *ngIf='creatingShipment' class='spinner-border spinner-border-sm' role='status'></div>
        </button>

        <button mdbBtn *ngIf='hasRates() && !disallowRatePush()' (click)='pushRate()' id='push-rate-button' data-cy='push-rate-button' class='float-right' color='success' title='Push Rate'>
            <mdb-icon fas *ngIf='!pushingRate' icon='cubes'></mdb-icon>
            <div *ngIf='pushingRate' class='spinner-border spinner-border-sm' role='status'></div>
        </button>

        <mdb-card *ngIf='!retrievingData && selectedCarrier && selectedCarrier.supportsShippingOptions()'>
            <mdb-card-body>
                <h5 class='h5-responsive'>Shipping Options - {{getSelectedRate().description}}</h5>

                <div *ngIf='selectedCarrier.isCanadaPost()'>
                </div>

                <div *ngIf='selectedCarrier.isFedex()'>
                    <div class='md-form m-1'>
                        <mdb-select-2 data-cy='fedex-signature-type' placeholder='Select signature type' [(ngModel)]='selectedCarrier.shippingOptions.signatureType'>
                            <mdb-select-option *ngFor='let signatureType of fedexUpsSignatureTypes' [value]='signatureType.code'>{{ signatureType.description }}</mdb-select-option>
                        </mdb-select-2>
                    </div>

                    <div class='md-form m-1'>
                        <input id='fedex-reference-number' data-cy='fedex-reference-number' mdbInput [(ngModel)]='selectedCarrier.shippingOptions.referenceNumber' class='form-control'/>
                        <label for='fedex-reference-number'>Ref #</label>
                    </div>
                </div>

                <div *ngIf='selectedCarrier.isUps()'>
                    <mdb-checkbox data-cy='ups-saturday-delivery' [(ngModel)]='selectedCarrier.saturdayDelivery' [inline]='true'>Saturday delivery?</mdb-checkbox>

                    <mdb-checkbox data-cy='fedex-return-label' [(ngModel)]='selectedCarrier.returnLabel' [inline]='true'>Include return label?</mdb-checkbox>

                    <div class='md-form m-1'>
                        <mdb-select-2 data-cy='ups-packaging-type' placeholder='Select packaging type' [(ngModel)]='selectedCarrier.shippingOptions.packagingType'>
                            <mdb-select-option *ngFor='let packagingType of upsPackagingTypes' [value]='packagingType.code'>{{ packagingType.description }}</mdb-select-option>
                        </mdb-select-2>
                    </div>

                    <div class='md-form m-1'>
                        <mdb-select-2 data-cy='ups-signature-type' placeholder='Select signature type' [(ngModel)]='selectedCarrier.shippingOptions.signatureType'>
                            <mdb-select-option *ngFor='let signatureType of fedexUpsSignatureTypes' [value]='signatureType.code'>{{ signatureType.description }}</mdb-select-option>
                        </mdb-select-2>
                    </div>

                    <div class='md-form m-1'>
                        <input id='ups-reference-number' data-cy='ups-reference-number' mdbInput [(ngModel)]='selectedCarrier.shippingOptions.referenceNumber' class='form-control'/>
                        <label for='ups-reference-number'>Ref #</label>
                    </div>
                </div>

                <div *ngIf='selectedCarrier.isPurolator()'>
                    <mdb-checkbox data-cy='purolator-saturday-delivery' [(ngModel)]='selectedCarrier.saturdayDelivery' [inline]='true'>Saturday delivery?</mdb-checkbox>
                </div>
            </mdb-card-body>
        </mdb-card>

        <table mdbTable data-cy='rates-table' *ngIf='hasRates()' hover='true' small='true'>
            <thead class="sticky-top">
            <tr>
                <th>Description</th>
                <th>Rate</th>
                <th></th>
            </tr>
            </thead>

            <tbody>
            <tr mdbTableCol *ngFor='let rate of rates'>
                <td>{{rate.description}} ({{rate.transitTime}} day)</td>
                <td>{{rate.rate | currency}}</td>
                <td>
                    <div class='form-check'>
                        <input type='radio' class='form-check-input' id='rate-{{rate.id}}' name='services' (change)='rateChanged(rate)' [checked]='rate.isSelected' mdbInput>
                        <label class='form-check-label' for='rate-{{rate.id}}'></label>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </mdb-card-body>
</mdb-card>
