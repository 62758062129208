import { Injectable } from '@angular/core';

import { AppDataService } from './app-data.service';

@Injectable()
export class PageService {

    private context;
    private fixture;

    public constructor(private appDataService: AppDataService) { }

    public setContext(selector) {
        this.context = selector;
    }

    public setFixture(fixture) {
        this.fixture = fixture;
        this.fixture.detectChanges();
    }

    public setCurrentOrder(order) {
        this.appDataService.currentOrder = order;
        this.fixture.detectChanges();
    }

    public detectChanges() {
        this.fixture.detectChanges();
    }

    public click(selector) {
        const button = this.get(selector);
        if (button) {
            button.click();
        }

        this.fixture.detectChanges();
    }

    public exists(selector) {
        return this.get(selector) !== null;
    }

    public getText(selector) {
        return this.get(selector).innerText;
    }

    public getPlaceholder(selector) {
        return this.get(selector).placeholder;
    }

    public setValue(selector, value) {
        this.get(selector).value = value;
        this.fixture.detectChanges();
    }

    public get(selector) {
        return this.fixture.debugElement.nativeElement.querySelector(this.buildSelector(selector));
    }

    public getAll(selector) {
        return this.fixture.debugElement.nativeElement.querySelectorAll(this.buildSelector(selector));
    }

    private buildSelector(selector) {
        return this.context ? this.context + ' ' + selector : selector;
    }
}
