import { Component, Input } from '@angular/core'
import { AppDataService } from '../../services/app-data.service'
import { ApiService } from '../../services/api.service'
import { ShippingService } from '../../services/shipping.service'
import { ToastService } from 'ng-uikit-pro-standard'
import { UserProfileService } from '../../services/user-profile.service'
import { FedexUpsSignatureType, UpsPackagingType } from '../../models/shipping-carrier'
import * as _ from 'lodash'

@Component({
    selector: 'app-shipping-rates',
    templateUrl: './rates.component.html',
})
export class ShippingRatesComponent {
    public creatingShipment: boolean
    public pushingRate: boolean
    public retrievingData: boolean
    public rates: Array<any>
    public shipment: any
    public selectedCarrier
    public fedexUpsSignatureTypes: Array<FedexUpsSignatureType>
    public upsPackagingTypes: Array<UpsPackagingType>

    public constructor(
        public data: AppDataService,
        private api: ApiService,
        private shipping: ShippingService,
        private toast: ToastService,
        private user: UserProfileService
    ) {
        this.rates = []
        this.shipment = null
        this.fedexUpsSignatureTypes = FedexUpsSignatureType.listAll()
        this.upsPackagingTypes = UpsPackagingType.listAll()

        this.data.currentOrderChanged.subscribe(() => {
            this.rates = []
        })
    }

    public hasCarriers(carriersComponent) {
        return carriersComponent.getSelectedCarriers().length > 0
    }

    public hasRates() {
        return this.rates.length > 0
    }

    public disallowRatePush() {
        return this.getSettings().disallowRatePush
    }

    public getRates(selectedCarriers) {
        const order = this.data.currentOrder
        if (order !== null) {
            this.fetchRates(selectedCarriers, order)
        }
    }

    public fetchRates(carriers, order) {
        this.retrievingData = true
        this.shipping.fetchRates(carriers, order).subscribe(
            (rates) => {
                this.retrievingData = false
                this.rates = rates
                this.processRates()
            },
            (err) => {
                this.retrievingData = false
                this.rates = []
                this.showErrorToast('Error retrieving rates: ' + err.error)
            }
        )
    }

    private processRates() {
        this.rates = _.sortBy(this.setSelectedRate(this.getSettings().defaultService), 'rate')

        if (!_.some(this.rates, 'isSelected')) {
            this.setSelectedRate(this.rates[0].description)
        }

        if (this.selectedCarrier.supportsReferenceNumberOption()) {
            this.selectedCarrier.shippingOptions.referenceNumber = this.data.currentOrder.number
        }
    }

    public pushRate(carrier, order, rate) {
        this.pushingRate = true

        const service = this.getSelectedRate()
        this.updateOrder(service.rate, service.description).subscribe(
            (res) => {
                this.pushingRate = false
                this.showSuccessToast('Rate pushed for order')
            },
            (err) => {
                this.pushingRate = false
                this.showErrorToast('Error updating rate for order: ' + err.error)
            }
        )
    }

    public createShipment() {
        this.creatingShipment = true

        const service = this.getSelectedRate()
        this.data.currentOrder.service = service.code
        this.shipping.createShipment(service.carrier, this.data.currentOrder, this.selectedCarrier.shippingOptions).subscribe(
            (shipment) => {
                if (shipment.charge <= 0) {
                    shipment.charge = service.rate
                }

                this.shipment = shipment
                this.creatingShipment = false

                this.updateOrder(shipment.charge, service.description, shipment.trackingNumber).subscribe((order) => {
                    this.data.currentOrder = order
                })

                if (shipment.packages && shipment.packages[0].labelImage) {
                    this.shipping.processShippingLabel(shipment)
                }

                this.showSuccessToast('Shipment created for order')
            },
            (err) => {
                this.shipment = null
                this.creatingShipment = false
                this.showErrorToast('Error creating shipment for order: ' + err.error)
            }
        )
    }

    public rateChanged(rate) {
        this.setSelectedRate(rate.description)
    }

    private getSelectedRate() {
        return _.find(this.rates, 'isSelected')
    }

    private setSelectedRate(description) {
        return _.map(this.rates, (rate) => {
            rate.isSelected = description === rate.description
            if (rate.isSelected) {
                this.setSelectedCarrier(rate)
            }

            return rate
        })
    }

    private setSelectedCarrier(rate) {
        this.selectedCarrier = _.find(this.data.availableCarriers, ['name', rate.carrier])
    }

    private updateOrder(rate, service, trackingNumber = null) {
        const shippingOrder = this.data.currentOrder.buildShippingOrder(rate, service, trackingNumber, this.getSettings().usingFlatRateShipping)

        return this.api.updateOrder(shippingOrder)
    }

    private showErrorToast(message) {
        this.toast.error(message, '', { opacity: 1 })
    }

    private showSuccessToast(message) {
        this.toast.success(message, '', { opacity: 1 })
    }

    private getSettings() {
        return this.user.getShippingSettings()
    }
}
