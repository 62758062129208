import { Component, OnInit } from '@angular/core'
import { Subject, Observable } from 'rxjs'
import { BoxService } from '../../services/box.service'
import { UserProfileService } from '../../services/user-profile.service'
import { AppDataService } from '../../services/app-data.service'
import { startWith, map } from 'rxjs/operators'
import * as _ from 'lodash'

@Component({
    selector: 'app-shipping-boxes',
    templateUrl: './client-boxes.component.html',
})
export class ClientBoxesComponent implements OnInit {
    public searchText = new Subject()
    public filteredOptions: Observable<any[]>

    public boxes
    public orderBoxes
    public newBox
    public box

    public constructor(private boxService: BoxService, private user: UserProfileService, private data: AppDataService) {}

    public ngOnInit() {
        this.reset()
        this.box = this.buildDefaultBox(true)
        this.fetchBoxes()
    }

    public reset() {
        this.orderBoxes = []
        this.newBox = this.buildDefaultBox()
    }

    public filter(value: string): any[] {
        const filterValue = value.toLowerCase()
        return this.boxes.filter((option: any) => option.description.toLowerCase().includes(filterValue))
    }

    public getDescription(item: any) {
        const config = item.boxConfig
        return item.description + ' (' + config.length + 'x' + config.width + 'x' + config.height + ') - ' + this.getDimensionUnit()
    }

    public getWeightUnit() {
        return this.getSettings().weightUnit
    }

    public getDimensionUnit() {
        return this.getSettings().dimensionUnit
    }

    private getSettings() {
        return this.user.getShippingSettings()
    }

    public isBoxSelected() {
        return !_.isNull(this.box.id) && !_.isNull(this.box.weight)
    }

    public isNewBoxSelected() {
        return !_.isNull(this.newBox.weight)
    }

    public definedBoxSelected() {
        this.box.weight = this.getBoxConfig(this.box.id).boxConfig.weight ?? null
    }

    public addBox() {
        const box = this.getBoxConfig(this.box.id)
        const orderBox = {
            length: box.boxConfig.length,
            width: box.boxConfig.width,
            height: box.boxConfig.height,
            weight: this.box.weight,
            desc: box.description,
            quantity: 1
        }

        this.orderBoxes.push(orderBox)
        this.data.currentOrder.boxes.push(orderBox)

        this.box.weight = null
    }

    public addNewBox() {
        const orderBox = {
            length: this.newBox.length,
            width: this.newBox.width,
            height: this.newBox.height,
            weight: this.newBox.weight,
            quantity: 1
        }

        this.orderBoxes.push(orderBox)
        this.data.currentOrder.boxes.push(orderBox)

        this.newBox.weight = null
        this.newBox.length = null
        this.newBox.width = null
        this.newBox.height = null
    }

    public definedBoxes() {
        return this.orderBoxes.length > 0 && this.data.currentOrder.boxes.length > 0
    }

    public definedOrderBoxes() {
        return !_.isUndefined(this.boxes) && this.boxes.length > 0
    }

    public getTotalWeight() {
        return _.reduce(this.orderBoxes, (sum, box) => {
            return sum + box.weight
        }, 0)
    }

    private getBoxConfig(id) {
        return _.find(this.boxes, (box) => {
            return id === box.id
        })
    }

    private buildDefaultBox(withDims?: boolean) {
        const defaultBox = { id: null, weight: null }
        const dims = { length: null, width: null, height: null }

        return withDims ? { ...defaultBox, ...dims } : defaultBox
    }

    private fetchBoxes() {
        this.boxService.fetchBoxes().subscribe((res) => {
            this.boxes = res
            this.filteredOptions = this.searchText.pipe(
                startWith(''),
                map((value: string) => this.filter(value))
            )
        })
    }

    public removeBox(index) {
        this.orderBoxes.splice(index, 1)
        this.data.currentOrder.boxes.splice(index, 1)
    }
}
