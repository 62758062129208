import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { AccountComponent } from './account/account.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { ShippingComponent } from './shipping/shipping.component';
import { StockComponent } from './stock/stock.component';
import { WarehouseTransferComponent } from './warehouse-transfer/warehouse-transfer.component';

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'account', component: AccountComponent, canActivate: [AuthGuardService] },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService] },
    { path: 'shipping', component: ShippingComponent, canActivate: [AuthGuardService] },
    { path: 'stock', component: StockComponent, canActivate: [AuthGuardService] },
    { path: 'warehouse-transfer', component: WarehouseTransferComponent, canActivate: [AuthGuardService] },
    { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.NgxAuthModule) },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class RoutingModule { }
