import { Component } from '@angular/core'
import { ApiService } from '../services/api.service'
import { MDBModalRef } from 'ng-uikit-pro-standard'
import * as _ from 'lodash'

@Component({
    selector: 'app-erp-credentials-dialog',
    templateUrl: './erp-credentials-dialog.component.html'
})
export class ErpCredentialsDialogComponent {

    public errorMessage
    public username
    public password
    public updating

    public constructor(private api: ApiService, public modalRef: MDBModalRef) { }

    public valid() {
        return !_.isEmpty(this.username) && !_.isEmpty(this.password)
    }

    public updateCredentials() {
        this.api.updateErpCredentials(this.username, this.password).subscribe(
            (res) => {
                this.updating = false
                this.modalRef.hide()
            },
            (err) => {
                this.updating = false
                this.errorMessage = 'Error updating your ERP credentials: ' + err.error
            }
        )
    }
}
