<mdb-card class='mb-2'>
    <mdb-card-body>
        <form class='form-inline'>
            <div class='md-form'>
                <input mdbInput #orderNumberField [(ngModel)]='orderNumber' name='order-number' id='order-search'
                    data-cy='order-search' type='search' class='form-control' required />
                <label for='order-search'>Order #</label>
            </div>

            <div class='md-form mt-3'>
                <button mdbBtn [disabled]='!orderNumber' (click)='fetchByNumber()' type='search'
                    data-cy='order-search-button' color='primary' title='Retrieve Order' mdbWavesEffect>
                    <mdb-icon *ngIf='!retrievingData' fas icon='search'></mdb-icon>
                    <div *ngIf='retrievingData' class='spinner-border spinner-border-sm' role='status'></div>
                </button>
            </div>

            <div class='md-form mt-3'>
                <button mdbBtn [disabled]='!data.currentOrder.isLoaded()' (click)='resetOrder()' type='reset'
                    data-cy='order-clear-button' title='Reset Order' class='btn-danger' mdbWavesEffect>
                    <strong>Clear</strong>
                </button>
            </div>
        </form>
    </mdb-card-body>
</mdb-card>

<mdb-card [hidden]='!data.currentOrder.isLoaded()' data-cy='order-details' class='animated fadeIn mb-2'>
    <mdb-card-body>
        <mdb-card-title>
            <div data-cy='order-title' class='row'>
                <div class='col-6'>Order #: {{ data.currentOrder.number }}</div>
                <div class='col-6 text-right'>
                    <mdb-checkbox data-cy='on-hold-control' [checkboxPosition]='"right"' [(ngModel)]='data.currentOrder.hold'>On Hold</mdb-checkbox>
                </div>
            </div>
        </mdb-card-title>

        <hr />

        <div class='row'>
            <div class='col-6'>
                <dl class='row'>
                    <dt class='col-sm-4'>Customer #:</dt>
                    <dd data-cy='customer-number' class='col-sm-8'>{{ data.currentOrder.getCustomerNumber() }}</dd>

                    <dt class='col-sm-4'>Customer Name:</dt>
                    <dd data-cy='customer-name' class='col-sm-8'>{{ data.currentOrder.customer.name }}</dd>

                    <dt class='col-sm-4'>Status:</dt>
                    <dd data-cy='order-status' class='col-sm-8'>{{ data.currentOrder.getStatusName() }}</dd>
                </dl>
            </div>
            <div class='col-6'>
                <dl class='row'>
                    <dt class='col-sm-3'>PO #:</dt>
                    <dd data-cy='po-number' class='col-sm-9'>{{ data.currentOrder.poNumber }}</dd>

                    <dt class='col-sm-3'>Ship Via:</dt>
                    <dd data-cy='ship-via' class='col-sm-9'>{{ data.currentOrder.shipVia }}</dd>
                </dl>
            </div>
        </div>
        <div class='row'>
            <div class='col-12'>
                <app-warehouses [items]='data.currentOrder.items' (selectedWarehouse)='setWarehouse($event)'></app-warehouses>
            </div>
        </div>

        <div *ngIf='data.currentOrder.hasTrackingNumber()' class='card-footer text-right bg-transparent'>
            <button mdbBtn type='submit' data-cy='reprint-label-button' (click)='reprintLabel()' title='Reprint Label'
                class='btn btn-primary' mdbWavesEffect>
                <strong>Reprint Label</strong>
                <div *ngIf='retrievingShipmentData' class='spinner-border spinner-border-sm' role='status'></div>
            </button>

            <button mdbBtn data-cy='void-shipment-button' (click)='voidShipment()' title='Void Shipment'
                class='btn btn-danger' mdbWavesEffect>
                <strong>Void Shipment</strong>
                <div *ngIf='voidingShipment' class='spinner-border spinner-border-sm' role='status'></div>
            </button>
        </div>
    </mdb-card-body>
</mdb-card>

<mdb-card class='mb-2'>
    <mdb-card-body>
        <mdb-tabset [buttonClass]='"classic-tabs tabs-primary"' [contentClass]='"card"' class='classic-tabs'>
            <mdb-tab *ngIf='data.currentOrder.isShipped()' heading='Shipments'>
                <app-shipping-tracking [trackingInfo]='trackingInfo' [errorMessage]='trackingError'>
                </app-shipping-tracking>
            </mdb-tab>
            <mdb-tab tabOrder='1' *ngIf='isShippingBoxesEnabled() && !data.currentOrder.isShipped()' heading='Boxes'>
                <app-shipping-boxes #boxes></app-shipping-boxes>
            </mdb-tab>
            <mdb-tab tabOrder='2' heading='Items'>
                <table data-cy='order-table' mdbTable hover='true' small='true'>
                    <thead class='sticky-top'>
                        <tr>
                            <th>Part #</th>
                            <th>Quantity</th>
                            <th>Weight ({{ getWeightUnit() }})</th>
                            <th>Length ({{ getDimensionUnit() }})</th>
                            <th>Width ({{ getDimensionUnit() }})</th>
                            <th>Height ({{ getDimensionUnit() }})</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr mdbTableCol *ngFor='let item of data.currentOrder.items'>
                            <td>{{ item.partNumber }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>
                                <div class='md-form m-0'>
                                    <input mdbInput
                                        [disabled]='isShippingBoxesEnabled() || data.currentOrder.isShipped()'
                                        type='number' [(ngModel)]='item.weight' class='form-control' />
                                </div>
                            </td>
                            <td>
                                <div class='md-form m-0'>
                                    <input mdbInput
                                        [disabled]='isShippingBoxesEnabled() || data.currentOrder.isShipped()'
                                        type='number' [(ngModel)]='item.length' class='form-control' />
                                </div>
                            </td>
                            <td>
                                <div class='md-form m-0'>
                                    <input mdbInput
                                        [disabled]='isShippingBoxesEnabled() || data.currentOrder.isShipped()'
                                        type='number' [(ngModel)]='item.width' class='form-control' />
                                </div>
                            </td>
                            <td>
                                <div class='md-form m-0'>
                                    <input mdbInput
                                        [disabled]='isShippingBoxesEnabled() || data.currentOrder.isShipped()'
                                        type='number' [(ngModel)]='item.height' class='form-control' />
                                </div>
                            </td>
                            <td>
                                <button *ngIf='!isShippingBoxesEnabled() && !data.currentOrder.isShipped()' mdbBtn
                                    color='warning' size='sm' (click)='deleteRow(index, item)' class='m-0'
                                    title='Delete Item'>
                                    <mdb-icon fas icon='trash-alt'></mdb-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class='grey lighten-2'>
                        <tr>
                            <td>Count: {{ data.currentOrder.getLineItemCount() }}</td>
                            <td>Total: {{ data.currentOrder.getTotalQuantity() }}</td>
                            <td>Total: {{ data.currentOrder.getTotalWeight() }}</td>
                            <td>Max: {{ data.currentOrder.getMaxLength() }}</td>
                            <td>Max: {{ data.currentOrder.getMaxWidth() }}</td>
                            <td>Max: {{ data.currentOrder.getMaxHeight() }}</td>
                            <td></td>
                        </tr>
                    </tfoot>
                    <tfoot *ngIf='!isShippingBoxesEnabled() && !data.currentOrder.isShipped()'>
                        <tr>
                            <td>
                                <div class='md-form m-0'>
                                    <input mdbInput data-cy='new-row-part-number' type='text' required
                                        [(ngModel)]='newRow.partNumber' class='form-control' />
                                </div>
                            </td>
                            <td>
                                <div class='md-form m-0'>
                                    <input mdbInput data-cy='new-row-quantity' type='number' required
                                        [(ngModel)]='newRow.quantity' class='form-control' />
                                </div>
                            </td>
                            <td>
                                <div class='md-form m-0'>
                                    <input mdbInput data-cy='new-row-weight' type='number' required
                                        [(ngModel)]='newRow.weight' class='form-control' />
                                </div>
                            </td>
                            <td>
                                <div class='md-form m-0'>
                                    <input mdbInput data-cy='new-row-length' type='number' required
                                        [(ngModel)]='newRow.length' class='form-control' />
                                </div>
                            </td>
                            <td>
                                <div class='md-form m-0'>
                                    <input mdbInput data-cy='new-row-width' type='number' required
                                        [(ngModel)]='newRow.width' class='form-control' />
                                </div>
                            </td>
                            <td>
                                <div class='md-form m-0'>
                                    <input mdbInput data-cy='new-row-height' type='number' required
                                        [(ngModel)]='newRow.height' class='form-control' />
                                </div>
                            </td>
                            <td>
                                <button mdbBtn data-cy='new-row-button' color='primary' size='sm' (click)='addRow()'
                                    [disabled]='!isNewRowValid()' class='m-0' title='Add Item'>
                                    <mdb-icon fas icon='plus-square'></mdb-icon>
                                </button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </mdb-tab>
            <mdb-tab tabOrder='3' heading='Shipping Address {{ data.currentOrder.shippingAddress.id }}'>
                <div class='md-form'>
                    <input mdbInput data-cy='shipping-name' type='text' required [(ngModel)]='data.currentOrder.shippingAddress.name' class='form-control' />
                    <label for='shipping-name'>Name</label>
                </div>
                <div class='md-form'>
                    <input mdbInput data-cy='shipping-line1' type='text' required [(ngModel)]='data.currentOrder.shippingAddress.line1' class='form-control' />
                    <label for='shipping-line1'>Line 1</label>
                </div>
                <div class='md-form'>
                    <input mdbInput data-cy='shipping-line2' type='text' required [(ngModel)]='data.currentOrder.shippingAddress.line2' class='form-control' />
                    <label for='shipping-line2'>Line 2</label>
                </div>
                <div class='md-form'>
                    <input mdbInput data-cy='shipping-city' type='text' required [(ngModel)]='data.currentOrder.shippingAddress.city' class='form-control' />
                    <label for='shipping-city'>City</label>
                </div>
                <div class='md-form'>
                    <input mdbInput data-cy='shipping-region-code' type='text' required minlength='2' maxlength='2' [(ngModel)]='data.currentOrder.shippingAddress.region' (ngModelChange)='changeUpperCase("region", data.currentOrder.shippingAddress.region)' class='form-control' />
                    <label for='shipping-region-code'>Region Code (ISO2)</label>
                </div>
                <div class='md-form'>
                    <input mdbInput data-cy='shipping-country-code' type='text' required minlength='2' maxlength='3' [(ngModel)]='data.currentOrder.shippingAddress.country' (ngModelChange)='changeUpperCase("country", data.currentOrder.shippingAddress.country)' class='form-control' />
                    <label for='shipping-country-code'>Country Code (ISO3)</label>
                </div>
                <div class='md-form'>
                    <input mdbInput data-cy='shipping-postal-zip' type='text' required [(ngModel)]='data.currentOrder.shippingAddress.postalZip' class='form-control' />
                    <label for='shipping-postal-zip'>Postal/Zip</label>
                </div>
                <div class='md-form'>
                    <input mdbInput data-cy='shipping-phone' type='tel' [(ngModel)]='data.currentOrder.shippingAddress.phoneNumber' class='form-control' />
                    <label for='shipping-phone'>Phone #</label>
                </div>
            </mdb-tab>
        </mdb-tabset>
    </mdb-card-body>
</mdb-card>
