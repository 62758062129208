import { Component } from '@angular/core';
import { AppDataService } from '../services/app-data.service';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})
export class HomeComponent {

    public flippedStates: any = {
        oneShip: false,
        oneAccount: false,
        oneStock: false,
        oneCatalog: false,
        oneReward: false
    };

    public constructor(public appDataService: AppDataService, public authService: AuthService) {
        this.appDataService.currentAppName = 'OneSuite';
    }

    public toggleView(appName) {
        this.flippedStates[appName] = !this.flippedStates[appName];
    }

}
