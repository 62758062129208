import { Component } from '@angular/core'
import { AppDataService } from '../services/app-data.service'

@Component({
    selector: 'app-shipping',
    templateUrl: './shipping.component.html'
})
export class ShippingComponent {

    public constructor(public appData: AppDataService) {
        this.appData.currentAppName = 'OneShip'
    }
}
