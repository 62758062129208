import { Injectable } from '@angular/core'
import { HttpService } from './http.service'
import { Observable } from 'rxjs'

@Injectable()
export class BoxService {
    constructor(private httpService: HttpService) {}

    public fetchBoxes(): Observable<any> {
        return this.httpService.get('shipping/boxes')
    }
}
