import { Component, OnInit, ViewChild } from '@angular/core'
import { ApiService } from '../services/api.service'
import { AppDataService } from '../services/app-data.service'
import { AuthService } from '../services/auth.service'
import { InvoiceDetailsDialogComponent } from '../invoice-details-dialog/invoice-details-dialog.component'
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard'
import { OrderDetailsDialogComponent } from '../order-details-dialog/order-details-dialog.component'
import { TrialExpiredDialogComponent } from '../trial-expired-dialog/trial-expired-dialog-component'
import { ToastService } from 'ng-uikit-pro-standard'
import { UserProfileService } from '../services/user-profile.service'
import { merge, Observable, of as observableOf } from 'rxjs'
import { catchError, map, startWith, switchMap } from 'rxjs/operators'
import * as _ from 'lodash'

export interface Customer {
    number: string
    name: string
    emailAddress: string
    balance: number
    creditLimit: number
    currency: string
    hasOneSuiteProfile: boolean
}

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
})
export class AccountComponent implements OnInit {
    public customer: Customer
    public invoices
    public invoiceFilter: string
    public invoicePage = 1
    public invoicePages = []
    public invoiceSort = 'invoiceDate'
    public invoiceSortDir = 'desc'
    public orders
    public orderFilter: string
    public orderPage = 1
    public orderPages = []
    public orderSort = 'orderDate'
    public orderSortDir = 'desc'
    public isLoadingCustomer = false
    public isLoadingInvoices = false
    public isLoadingOrders = false
    public currentPdfItemId: string
    public isSendingAccountRegistrationLink = false
    public modalRef: MDBModalRef
    public objectKeys = Object.keys

    public constructor(
        public appData: AppDataService,
        private api: ApiService,
        private authService: AuthService,
        private modalService: MDBModalService,
        private toast: ToastService,
        private userProfile: UserProfileService
    ) {
        this.appData.currentAppName = 'OneAccount'
    }

    public ngOnInit() {
        if (this.isTrialAccount() && !this.isActiveTrial()) {
            this.openTrialExpiredDialog()
        }

        const customerNumber = this.userProfile.getCustomerNumber()

        if (!_.isNull(customerNumber)) {
            this.fetchByNumber(customerNumber)
        }
    }

    public fetchByNumber(num) {
        this.isLoadingCustomer = true
        this.api.fetchCustomerByNumber(num).subscribe(
            (customer) => {
                this.isLoadingCustomer = false
                this.customer = customer ? customer : null

                if (this.customer) {
                    this.retrieveOrders()
                    this.retrieveInvoices()
                } else {
                    this.showErrorToast('No customer found for # ' + num)
                }
            },
            (err) => {
                this.isLoadingCustomer = false
                this.showErrorToast('Error retrieving customer: ' + err.error)
            }
        )
    }

    public retrieveOrders() {
        this.isLoadingOrders = true
        this.api.fetchOrders(this.customer.number, this.orderFilter, this.orderPage, this.orderSort, this.orderSortDir).subscribe(
            (orders) => {
                this.isLoadingOrders = false
                this.orderPages = this.buildPageArray(orders.itemCount, orders.limit)
                this.orders = orders ? orders.items : null
            },
            (err) => {
                this.isLoadingOrders = false
                this.showErrorToast('Error retrieving orders: ' + err.error)
            }
        )
    }

    public applyOrderFilter(filterValue) {
        this.orderFilter = filterValue.trim().toLowerCase()
        this.retrieveOrders()
    }

    public resetOrderFilter() {
        this.orderFilter = ''
        this.retrieveOrders()
    }

    public applyOrderSort(field) {
        this.orderSort = field
        this.orderSortDir = this.updatedSortDir(this.orderSortDir)
        this.retrieveOrders()
    }

    public hasActiveOrderClass(page) {
        return page === this.orderPage ? 'active' : ''
    }

    public setOrderPage(page) {
        this.orderPage = page
        this.retrieveOrders()
    }

    public openOrderDetailsDialog(order) {
        this.modalRef = this.modalService.show(OrderDetailsDialogComponent, {
            class: 'modal-fluid modal-full-height',
            data: { order },
        })
    }

    public fetchOrderPdf(order) {
        return this.fetchPdf(order, 'order')
    }

    public retrieveInvoices() {
        this.isLoadingInvoices = true
        this.api.fetchInvoices(this.customer.number, this.invoiceFilter, this.invoicePage, this.invoiceSort, this.invoiceSortDir).subscribe(
            (invoices) => {
                this.isLoadingInvoices = false
                this.invoicePages = this.buildPageArray(invoices.itemCount, invoices.limit)
                this.invoices = invoices ? invoices.items : null
            },
            (err) => {
                this.isLoadingInvoices = false
                this.showErrorToast('Error retrieving invoices: ' + err.error)
            }
        )
    }

    public applyInvoiceFilter(filterValue: string) {
        this.invoiceFilter = filterValue.trim().toLowerCase()
        this.retrieveInvoices()
    }

    public resetInvoiceFilter() {
        this.invoiceFilter = ''
        this.retrieveInvoices()
    }

    public applyInvoiceSort(field) {
        this.invoiceSort = field
        this.invoiceSortDir = this.updatedSortDir(this.invoiceSortDir)
        this.retrieveInvoices()
    }

    public hasActiveInvoiceClass(page) {
        return page === this.invoicePage ? 'active' : ''
    }

    public setInvoicePage(page) {
        this.invoicePage = page
        this.retrieveInvoices()
    }

    public openInvoiceDetailsDialog(invoice) {
        this.modalRef = this.modalService.show(InvoiceDetailsDialogComponent, {
            class: 'modal-fluid modal-full-height',
            data: { invoice },
        })
    }

    public fetchInvoicePdf(invoice) {
        return this.fetchPdf(invoice, 'invoice')
    }

    public displayOneSuiteRegistrationLink() {
        return this.customer.hasOneSuiteProfile === false && this.customer.emailAddress !== '' && this.userProfile.isActiveAccount(this.appData.currentAppName)
    }

    public isActiveTrial() {
        return this.userProfile.isActiveTrial(this.appData.currentAppName)
    }

    public isTrialAccount() {
        return this.userProfile.isTrialAccount(this.appData.currentAppName)
    }

    public sendActivationLink() {
        this.isSendingAccountRegistrationLink = true

        const params = {
            email: this.customer.emailAddress,
            num: this.customer.number,
            name: this.customer.name,
            clientId: this.userProfile.getClientId(),
            source: 'account',
        }

        this.api.sendAccountActivationLink(params).subscribe(
            (accountActivation) => {
                this.isSendingAccountRegistrationLink = false
                this.showSuccessToast('Activation email sent')
            },
            (err) => {
                this.isSendingAccountRegistrationLink = false
                this.showErrorToast('Error sending activation email: ' + err.error)
            }
        )
    }

    public isLoadingPdf(item) {
        return !_.isEmpty(this.currentPdfItemId) && this.currentPdfItemId === item.number ? true : false
    }

    private isOneSuiteAccountActive() {
        return this.customer.hasOneSuiteProfile
    }

    private showSuccessToast(message) {
        this.toast.success(message, 'Success', { opacity: 1 })
    }

    private showErrorToast(message) {
        this.toast.error(message, '', { opacity: 1 })
    }

    private fetchPdf(item, type) {
        this.currentPdfItemId = item.number
        this.api.fetchPdf(type, item.number).subscribe(
            (response) => {
                this.downloadFile(response, 'application/pdf', item, type)
                this.currentPdfItemId = null
            },
            (err) => {
                this.showErrorToast('Unable to generate PDF. ' + err.error)
                this.currentPdfItemId = null
            }
        )
    }

    private downloadFile(data, type, item, documentType) {
        const blob = new Blob([data], { type })

        const url = window.URL.createObjectURL(blob)
        const downloadLink = document.createElement('a')
        const fileName = documentType + '-' + item.number + '.pdf'

        downloadLink.href = url
        downloadLink.download = fileName
        downloadLink.click()
    }

    private buildPageArray(itemCount, limit) {
        return _.range(1, _.ceil(itemCount / limit) + 1)
    }

    private updatedSortDir(currentSortDir) {
        return currentSortDir === 'desc' ? 'asc' : 'desc'
    }

    private openTrialExpiredDialog() {
        this.modalRef = this.modalService.show(TrialExpiredDialogComponent, {
            ignoreBackdropClick: true,
            data: { app: this.appData.currentAppName },
        })
    }
}
