export class ShippingCarrier {

    public name: string
    public service: string
    public shippingOptions: object
    public shippingOptionDefaults: object
    public isSelected: boolean
    public isDisplayed: boolean

    public constructor(data) {
        this.name = data.id
        this.service = data.service,
        this.shippingOptions = data.shippingOptionDefaults
        this.shippingOptionDefaults = data.shippingOptionDefaults
        this.isSelected = false
        this.isDisplayed = true
    }

    public supportsShippingOptions() {
        return !this.isCanadaPost()
    }

    public supportsReferenceNumberOption() {
        return this.isFedex() || this.isUps()
    }

    public isCanadaPost() {
        return this.name === 'CanadaPost'
    }

    public isFedex() {
        return this.name === 'Fedex'
    }

    public isUps() {
        return this.name === 'Ups'
    }

    public isPurolator() {
        return this.name === 'Purolator'
    }
}

export class FedexUpsSignatureType {

    public code: string
    public description: string

    public constructor(code: string, description: string) {
        this.code = code
        this.description = description
    }

    public static listAll(): Array<FedexUpsSignatureType> {
        return [
            new FedexUpsSignatureType('ADULT', 'Adult signature required'),
            new FedexUpsSignatureType('DIRECT', 'Signature Required'),
            new FedexUpsSignatureType('INDIRECT', 'Signature may be required (default)'),
            new FedexUpsSignatureType('NO_SIGNATURE_REQUIRED', 'Signature not required'),
            new FedexUpsSignatureType('SERVICE_DEFAULT', 'Default for selected service')
        ]
    }
}


export class UpsPackagingType {

    public code: string
    public description: string

    public constructor(code: string, description: string) {
        this.code = code
        this.description = description
    }

    public static listAll(): Array<UpsPackagingType> {
        return [
            new UpsPackagingType('01', 'UPS Letter'),
            new UpsPackagingType('02', 'Customer Supplied Package'),
            new UpsPackagingType('03', 'Tube'),
            new UpsPackagingType('04', 'PAK'),
            new UpsPackagingType('21', 'UPS Express Box'),
            new UpsPackagingType('24', 'UPS 25KG Box'),
            new UpsPackagingType('25', 'UPS 10KG Box'),
            new UpsPackagingType('30', 'Pallet'),
            new UpsPackagingType('2a', 'Small Express Box'),
            new UpsPackagingType('2b', 'Medium Express Box'),
            new UpsPackagingType('2c', 'Large Express Box')
        ]
    }
}
