<div data-cy='warehouses-select'>
    <div class='col-md-6'>
        <div class='md-form m-1'>
            <mdb-select-2 placeholder='Warehouses' [(ngModel)]='warehouse' (ngModelChange)='warehouseSelected()'
                [allowClear]='false' data-cy='warehouses-select-options'>
                <mdb-select-filter [ngModel]='searchText | async' (ngModelChange)='searchText.next($event)'></mdb-select-filter>
                <mdb-select-option *ngFor='let option of filteredOptions | async' [value]='option.number'>{{ option.description }}</mdb-select-option>
            </mdb-select-2>
        </div>
    </div>
</div>
