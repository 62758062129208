import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
    selector: 'app-order-details-dialog',
    templateUrl: './order-details-dialog.component.html'
})
export class OrderDetailsDialogComponent implements OnInit {

    public errorMessage;
    public order;
    public retrievingDetails;

    public constructor(private apiService: ApiService, public modalRef: MDBModalRef) { }

    public ngOnInit() {
        this.retrievingDetails = true;
        this.apiService.fetchOrderByNumber(this.order.number).subscribe(
            order => {
                this.retrievingDetails = false;
                this.order = order;
            },
            err => {
                this.retrievingDetails = false;
                this.errorMessage = 'Error retrieving order: ' + err.error;
            }
        );
    }

}
