import { ApiService } from '../services/api.service'
import { AppDataService } from '../services/app-data.service'
import { Component } from '@angular/core'
import { StockPresenterService } from '../services/stock-presenter.service'
import { ToastService } from 'ng-uikit-pro-standard'
import * as _ from 'lodash'

// @ts-ignore
import vendors from '../../fixtures/vendors.json'
// @ts-ignore
import products from '../../fixtures/products.json'

@Component({
    selector: 'app-stock',
    templateUrl: './stock.component.html'
})

export class StockComponent {
    public searchString = null
    public isLoadingProduct = false
    public product
    public vendors = null;
    public selectedVendor = null
    public products
    public vendorDetails = null

    public constructor(
        public data: AppDataService,
        public api: ApiService,
        private presenter: StockPresenterService,
        private toast: ToastService,
    ) {
        this.data.currentAppName = 'OneStock'
        this.product = null
        this.vendors = vendors
    }

    public fetchProduct(itemId, isAlt = false) {
        if (!isAlt) {
            this.product = null
        }

        this.isLoadingProduct = true
        this.toast.clear()

        this.api.fetchProduct(itemId).subscribe(
            product => {
                this.isLoadingProduct = false

                if (product) {
                    this.prepProduct(product, isAlt)
                } else {
                    this.toast.error('No product found for item ' + itemId, '', { opacity: 1 })
                }
            },
            err => {
                this.isLoadingProduct = false
                this.toast.error('Error retrieving stock: ' + err.error, '', { opacity: 1 })
            }
        );
    }

    public prepProduct(product, isAlt) {
        if (isAlt) {
            const alt = _.find(this.product.alternates, { number: product.number })
            alt.warehouses = product.warehouses
            this.presenter.setStockTotals(alt)
        } else {
            this.product = this.presenter.setStockTotals(product)
        }

        if (product.alternates.length > 0) {
            product.alternates.forEach(alternate => {
                this.fetchProduct(alternate.number, true)
            })
        }
    }

    public getVendorProducts() {
        if (this.product) {
            this.product = null
        }

        this.products = products
        this.vendorDetails = this.vendors.find((item => item.number === this.selectedVendor))
    }
}
