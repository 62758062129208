import { Component } from '@angular/core'
import { AppDataService } from './services/app-data.service'
import { AuthService } from './services/auth.service'
import { LogDialogComponent } from './log-dialog/log-dialog.component'
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard'
import { UserProfileService } from './services/user-profile.service'
import * as _ from 'lodash'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {

    public options: any
    public modalRef: MDBModalRef

    public constructor(public auth: AuthService, private userProfile: UserProfileService, private data: AppDataService, private modal: MDBModalService) {}

    public currentAppName() {
        return this.data.currentAppName
    }

    public getEmail() {
        return this.userProfile.getEmail()
    }

    public isActiveTrial() {
        const appName = this.currentAppName()

        return this.userProfile.isTrialAccount(appName) && this.userProfile.isActiveTrial(appName)
    }

    public getTrialExpiry() {
        return this.userProfile.getTrialExpiry(this.currentAppName())
    }

    public isAccountAppEnabled() {
        return this.userProfile.isAccountAppEnabled()
    }

    public isStockAppEnabled() {
        return this.userProfile.isStockAppEnabled()
    }

    public isShippingAppEnabled() {
        return this.userProfile.isShippingAppEnabled()
    }

    public isWarehouseTransferAppEnabled() {
        return this.userProfile.isWarehouseTransferAppEnabled()
    }

    public openLogDialog(sideNav) {
        sideNav.hide()
        this.modalRef = this.modal.show(
            LogDialogComponent,
            {
                class: 'modal-fluid modal-full-height overflow-y: auto',
                data: {}
            }
        )
    }
}
