<div *ngIf='definedOrderBoxes()' data-cy='client-boxes-select'>
    <mdb-card class='mt-2'>
        <mdb-card-body class='p-0'>
            <div class='md-form m-1 input-group'>
                <div class='col-md-2 input-group-prepend'>
                    <span class='input-group-text md-addon'>Select a box</span>
                </div>

                <div class='col-md-6'>
                    <div class='md-form m-1'>
                        <mdb-select-2 placeholder='Defined boxes...' [(ngModel)]='box.id' (ngModelChange)='definedBoxSelected()' [allowClear]='true' data-cy='client-boxes-select-options'>
                            <mdb-select-filter
                                [ngModel]='searchText | async'
                                (ngModelChange)='searchText.next($event)'>
                            </mdb-select-filter>
                            <mdb-select-option *ngFor='let option of filteredOptions | async' [value]='option.id'>{{getDescription(option)}}</mdb-select-option>
                        </mdb-select-2>
                    </div>
                </div>

                <div class='col-md-2'>
                    <div class='md-form m-1'>
                        <input id='box-weight' mdbInput data-cy='box-weight' type='number' [(ngModel)]='box.weight' class='form-control'/>
                        <label for='box-weight'>Weight ({{getWeightUnit()}})</label>
                    </div>
                </div>

                <div class='col-md-2 text-center'>
                    <div class='md-form m-1'>
                        <button mdbBtn [disabled]='!isBoxSelected()' data-cy='box-button' class='center mt-0' color='primary' size='sm' title='Add Box' (click)='addBox()'>
                            <mdb-icon fas icon='plus-square'></mdb-icon>
                        </button>
                    </div>
                </div>
            </div>
        </mdb-card-body>
    </mdb-card>
</div>

<mdb-card class='mt-2'>
    <mdb-card-body class='p-0'>
        <div class='md-form m-1 input-group'>
            <div class='col-md-2 input-group-prepend'>
                <span class='input-group-text md-addon'>Enter a box</span>
            </div>
            <div class='col-md-2'>
                <div class='md-form m-1'>
                    <input id='new-box-length' mdbInput data-cy='new-box-length' type='number' [(ngModel)]='newBox.length' class='form-control' />
                    <label for='new-box-length'>Length ({{getDimensionUnit()}})</label>
                </div>
            </div>

            <div class='col-md-2'>
                <div class='md-form m-1'>
                    <input id='new-box-width' mdbInput data-cy='new-box-width' type='number' [(ngModel)]='newBox.width' class='form-control' />
                    <label for='new-box-width'>Width ({{getDimensionUnit()}})</label>
                </div>
            </div>

            <div class='col-md-2'>
                <div class='md-form m-1'>
                    <input id='new-box-height' mdbInput data-cy='new-box-height' type='number' [(ngModel)]='newBox.height' class='form-control' />
                    <label for='new-box-height'>Height ({{getDimensionUnit()}})</label>
                </div>
            </div>

            <div class='col-md-2'>
                <div class='md-form m-1'>
                    <input id='new-box-weight' mdbInput data-cy='new-box-weight' type='number' [(ngModel)]='newBox.weight' class='form-control' />
                    <label for='new-box-weight'>Weight ({{getWeightUnit()}})</label>
                </div>
            </div>

            <div class='col-md-2 text-center'>
                <div class='md-form m-1'>
                    <button mdbBtn [disabled]='!isNewBoxSelected()' (click)='addNewBox()' data-cy='new-box-button' class='center m-0' color='primary' size='sm' title='Add Box'>
                        <mdb-icon fas icon='plus-square'></mdb-icon>
                    </button>
                </div>
            </div>
        </div>
    </mdb-card-body>
</mdb-card>

<div *ngIf='definedBoxes()'>
    <mdb-card class='mt-2 animated fadeIn'>
        <mdb-card-body>
            <mdb-card-title>To ship</mdb-card-title>
            <table data-cy='box-table' mdbTable small='true'>
                <thead class='sticky-top'>
                    <tr>
                        <th></th>
                        <th class='text-center'>Length ({{getDimensionUnit()}})</th>
                        <th class='text-center'>Width ({{getDimensionUnit()}})</th>
                        <th class='text-center'>Height ({{getDimensionUnit()}})</th>
                        <th class='text-center'>Weight ({{getWeightUnit()}})</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let box of orderBoxes; index as idx' class='animated fadeIn'>
                        <td class='text-center'>{{box.desc}}</td>
                        <td class='text-center'>{{box.length}}</td>
                        <td class='text-center'>{{box.width}}</td>
                        <td class='text-center'>{{box.height}}</td>
                        <td class='text-center'>{{box.weight}}</td>
                        <td class='text-center'>
                            <button mdbBtn (click)='removeBox(idx)' data-cy='remove-box-button' type='button' class='center m-0' size='sm' title='Remove Box' color='danger' mdbWavesEffect>
                                <mdb-icon fas icon='trash'></mdb-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class='text-center'>Boxes: {{this.orderBoxes.length}}</td>
                        <td class='text-center'>{{getTotalWeight()}} {{getWeightUnit()}}</td>
                    </tr>
                </tfoot>
            </table>
        </mdb-card-body>
    </mdb-card>
</div>
