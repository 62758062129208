import { Injectable, Output, EventEmitter } from '@angular/core'
import { SalesOrder } from '../models/sales-order'

@Injectable()
export class AppDataService {
    public currentOrder: any
    public currentProduct: any
    public currentAppName: string
    public selectedCompanyId: string
    public availableCarriers: any

    @Output() currentOrderChanged: EventEmitter<any> = new EventEmitter();

    public constructor() {
        this.currentOrder = new SalesOrder()
        this.currentAppName = 'OneSuite'
        this.currentProduct = undefined
        this.selectedCompanyId = ''
        this.availableCarriers = []
    }

    public emitCurrentOrderChanged() {
        this.currentOrderChanged.emit()
    }
}
