import { ApiService } from '../services/api.service'
import { AppDataService } from '../services/app-data.service'
import { Component, ViewChild, ElementRef } from '@angular/core'
import { ClientBoxesComponent } from '../shipping/boxes/client-boxes.component'
import { ShippingService } from '../services/shipping.service'
import { ShippingTrackingComponent } from '../shipping/tracking/tracking.component'
import { ToastService } from 'ng-uikit-pro-standard'
import { UserProfileService } from '../services/user-profile.service'
import * as _ from 'lodash'

@Component({
    selector: 'app-order-lookup',
    templateUrl: './order-lookup.component.html',
})
export class OrderLookupComponent {
    @ViewChild('orderNumberField') orderNumberField: ElementRef
    @ViewChild('boxes') boxes: ClientBoxesComponent

    public newRow: any
    public retrievingData: boolean
    public retrievingShipmentData: boolean
    public voidingShipment: boolean
    public trackingInfo: any
    public trackingError: string
    public orderNumber: string

    public constructor(
        public data: AppDataService,
        public api: ApiService,
        private shipping: ShippingService,
        private user: UserProfileService,
        private toast: ToastService
    ) {
        this.newRow = this.data.currentOrder.buildDefaultLineItem()
    }

    public fetchByNumber() {
        this.retrievingData = true
        this.clearData()

        this.api.fetchOrderByNumber(this.orderNumber).subscribe(
            (order) => {
                this.retrievingData = false

                if (order) {
                    if (this.canShipOrder(order)) {
                        this.data.currentOrder = order

                        this.setOrderHold()
                        this.selectCarrier()
                        this.setCompanySpecificCarriers()

                        if (this.data.currentOrder.hasTrackingNumber()) {
                            const carrier = this.getCarrier(order.shipments[0].carrier)
                            this.shipping.fetchTrackingInfo(carrier, order.shipments[0].trackingNumber).subscribe(
                                (trackingInfo) => {
                                    this.trackingInfo = trackingInfo[0]
                                    this.trackingError = null
                                },
                                (err) => {
                                    this.trackingInfo = null
                                    this.trackingError = 'Problem retrieving tracking information.'
                                }
                            )
                        } else {
                            this.trackingError = null
                            this.trackingInfo = null
                        }
                    } else {
                        this.showErrorToast('Error retrieving order: incorrect order status')
                    }
                } else {
                    this.showErrorToast('No order found for # ' + this.orderNumber)
                }
            },
            (err) => {
                this.retrievingData = false
                this.showErrorToast('Error retrieving order: ' + err.error)
            }
        )
    }

    private clearData() {
        this.toast.clear()
        this.data.emitCurrentOrderChanged()
        if (this.data.currentOrder) {
            this.data.currentOrder.reset()
        }

        if (this.boxes) {
            this.boxes.reset()
        }
    }

    public setWarehouse(warehouse) {
        this.data.currentOrder.shippingOrigin = warehouse
    }

    private selectCarrier() {
        const selectedCarrier = this.data.currentOrder.shipVia ? _.split(this.data.currentOrder.shipVia, ' ')[0] : this.getSettings().defaultCarrier
        if (selectedCarrier) {
            const selected = _.find(this.data.availableCarriers, (carrier) => selectedCarrier.toUpperCase() === carrier.service.toUpperCase())
            if (selected) {
                selected.isSelected = true
            }
        }
    }

    private setCompanySpecificCarriers() {
        const specificCarriers = _.get(this.user.getShippingSettings(), 'erpCompanySpecificCarriers.' + this.data.selectedCompanyId, [])
        if (!_.isEmpty(specificCarriers)) {
            _.forEach(this.data.availableCarriers, (carrier) => {
                carrier.isDisplayed = specificCarriers.includes(carrier.name)
            })
        }
    }

    public reprintLabel() {
        this.retrievingShipmentData = true
        this.toast.clear()

        this.shipping.fetchShipment(this.data.currentOrder.number).subscribe(
            (shipment) => {
                this.retrievingShipmentData = false

                if (shipment.packages && shipment.packages[0].labelImage) {
                    this.shipping.processShippingLabel(shipment)
                } else {
                    this.showErrorToast('No shipment information is available for order # ' + this.data.currentOrder.number)
                }
            },
            (err) => {
                this.retrievingShipmentData = false
                this.showErrorToast('Error retrieving shipment information: ' + err.error)
            }
        )
    }

    public resetOrder() {
        this.data.currentOrder.reset()
        this.orderNumber = null
        this.orderNumberField.nativeElement.focus()
    }

    public getWeightUnit() {
        return this.getSettings().weightUnit
    }

    public getDimensionUnit() {
        return this.getSettings().dimensionUnit
    }

    public addRow() {
        this.data.currentOrder.addLineItem(this.newRow)
        this.newRow = this.data.currentOrder.buildDefaultLineItem()
    }

    public deleteRow(index, item) {
        if (confirm('Are you sure you want to delete item ' + item.partNumber)) {
            this.data.currentOrder.removeLineItem(index, 1)
        }
    }

    public isNewRowValid() {
        return !_.isEmpty(this.newRow.partNumber) && this.newRow.quantity > 0 && this.newRow.weight > 0
    }

    public isShippingBoxesEnabled() {
        return !_.isUndefined(this.getSettings().shippingBoxes) && this.getSettings().shippingBoxes
    }

    public changeUpperCase(property, value) {
        this.data.currentOrder.shippingAddress[property] = _.toUpper(this.data.currentOrder.shippingAddress[property])
    }

    public voidShipment() {
        this.voidingShipment = true
        this.shipping.voidShipment(this.getCarrier(this.data.currentOrder.shipments[0].carrier), this.data.currentOrder)
        this.voidingShipment = false
    }

    private showErrorToast(message) {
        this.data.currentOrder.reset()
        this.toast.error(message, '', { opacity: 1 })
    }

    private canShipOrder(order) {
        const statusFilters = this.getSettings().orderStatusFilter ?? this.getSettings().orderStatusFilter

        if (!_.isNull(statusFilters)) {
            return !_.find(statusFilters, (status) => {
                return status.toUpperCase() === order.status.toUpperCase()
            })
        }

        return true
    }

    private setOrderHold() {
        const holdWarehouses = _.get(this.user.getShippingSettings(), 'holdWarehouses', null)

        if (!this.data.currentOrder.hold) {
            if (_.isNull(holdWarehouses) || !this.data.currentOrder.isProcessed()) {
                this.data.currentOrder.hold = false
            }

            _.forEach(this.data.currentOrder.items, (item) => {
                if (this.itemWarehouseIsOnHold(item, holdWarehouses)) {
                    this.data.currentOrder.hold = true
                }
            })
        }
    }

    private itemWarehouseIsOnHold(item, holdwarehouses) {
        let isOnHold = false
        _.forEach(holdwarehouses, (warehouse) => {
            if (warehouse === item.warehouse) {
                isOnHold = true
            }
        })

        return isOnHold
    }

    private getCarrier(carrier) {
        let temp = _.upperFirst(_.toLower(_.split(carrier, ' ')[0]))
        if (temp === 'Canadapost') {
            temp = 'CanadaPost'
        }

        return temp
    }

    private getSettings() {
        return this.user.getShippingSettings()
    }
}
