import { AppDataService } from '../services/app-data.service'
import { HttpService } from './http.service'
import { Injectable } from '@angular/core'
import { SalesOrder } from '../models/sales-order'
import { UserProfileService } from '../services/user-profile.service'
import { of } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable()
export class ApiService {
    public constructor(private httpService: HttpService, private appData: AppDataService, private user: UserProfileService) {}

    public fetchCompanies() {
        return this.httpService.get('one-api/companies', this.getHeaders())
    }

    public fetchCustomerByNumber(num) {
        return this.httpService.get('one-api/customers/' + num, this.getHeaders())
    }

    public fetchInvoices(customerNum, filter, page, sort, sortDir) {
        const queryString = this.buildQueryString(filter, page, sort, sortDir)
        const endpoint = this.buildCustomerContextEndpoint(customerNum, 'invoices')

        return this.httpService.get(`one-api/${endpoint}${queryString}`, this.getHeaders())
    }

    public fetchOrders(customerNum, filter, page, sort, sortDir) {
        const queryString = this.buildQueryString(filter, page, sort, sortDir)
        const endpoint = this.buildCustomerContextEndpoint(customerNum, 'orders')

        return this.httpService.get(`one-api/${endpoint}${queryString}`, this.getHeaders())
    }

    public fetchWarehouses() {
        return this.httpService.get('one-api/warehouses', this.getHeaders())
    }

    private buildQueryString(numberFilter, page, sort, sortDir) {
        const params = []
        if (numberFilter) {
            params.push(`number=${numberFilter}`)
        }

        if (page) {
            params.push(`page=${page}`)
        }

        if (sort) {
            params.push(`sort=${sort}:${sortDir}`)
        }

        let queryString = ''
        if (params.length > 0) {
            queryString = '?' + params.join('&')
        }

        return queryString
    }

    private buildCustomerContextEndpoint(customerNum, listRoute) {
        let endpoint = listRoute
        if (customerNum) {
            endpoint = `customers/${customerNum}/${endpoint}`
        }

        return endpoint
    }

    public fetchInvoiceByNumber(num) {
        return this.httpService.get(`one-api/invoices/${num}`, this.getHeaders())
    }

    public fetchOrderByNumber(num) {
        return this.httpService.get(`one-api/orders/${num}`, this.getHeaders()).pipe(map((data) => new SalesOrder(data)))
    }

    public fetchPdf(type, num) {
        return this.httpService.getPdf(`one-api/docs/${type}/${num}/pdf`)
    }

    public updateOrder(order) {
        return this.httpService.put('one-api/orders', order).pipe(map((data) => new SalesOrder(data)))
    }

    public fetchProduct(itemId) {
        return this.httpService.get('one-api/products/' + itemId, this.getHeaders())
    }

    public fetchProducts(queryString: string) {
        return this.httpService.get(`one-api/products/${queryString}`, this.getHeaders())
    }

    public fetchProductCategories() {
        return this.httpService.get(`one-api/products/categories`, this.getHeaders())
    }

    public transferInventory(params) {
        return this.httpService.post(`one-api/inventory/transfer`, params, this.getHeaders())
    }

    public sendAccountActivationLink(params) {
        return this.httpService.post('auth/activate', params)
    }

    public updateErpCredentials(username, password) {
        return this.httpService.put('one-api/erp-credentials', { username, password })
    }

    private getHeaders() {
        return {
            'Company-Id': this.appData.selectedCompanyId,
            'User-Id': this.user.getId(),
        }
    }
}
