<div data-cy='product-categories-select'>
    <div class='col-md-6'>
        <div class='md-form m-1'>
            <mdb-select-2 placeholder='All' [(ngModel)]='category' (ngModelChange)='categorySelected()'
                [allowClear]='true' data-cy='product-categories-select-options'>
                <mdb-select-filter [ngModel]='searchText | async' (ngModelChange)='searchText.next($event)'></mdb-select-filter>
                <mdb-select-option *ngFor='let option of filteredOptions | async' [value]='option.number'>{{ option.description }}</mdb-select-option>
            </mdb-select-2>
        </div>
    </div>
</div>
