<app-company></app-company>

<section *nbIsGranted="['view', 'customer-search']" class='z-depth-2 p-3 m-3'>
    <form class='form-inline'>
        <div class='md-form'>
            <input mdbInput #customerNumber data-cy='customer-search' type='search' class='form-control' required />
            <label>Customer #</label>
        </div>

        <button mdbBtn type='search' data-cy='customer-search-button' color='primary' (click)='fetchByNumber(customerNumber.value);' [disabled]='!customerNumber.value' title='Retrieve Customer' mdbWavesEffect>
            <mdb-icon *ngIf='!isLoadingCustomer' fas icon='search'></mdb-icon>
            <div *ngIf='isLoadingCustomer' class='spinner-border spinner-border-sm' role='status'></div>
        </button>
    </form>
</section>

<mdb-tabset data-cy='account-tabs' *ngIf='customer' [buttonClass]='"classic-tabs tabs-primary"' [contentClass]='"card"' class='classic-tabs' >
    <mdb-tab heading='Details'>
        <section class='p-2'>
            <dl *nbIsGranted="['view', 'account-status']" class='row'>
                <dt class='col-2'>OneSuite Account: </dt>
                <dd *ngIf='isOneSuiteAccountActive()' class='col-10'>Active</dd>

                <dd *ngIf='isActiveTrial()' data-cy='account-upgrade-message' class='col-10'>
                    Upgrade your trial account to add your customers to OneAccount!
                </dd>

                <dd *ngIf='displayOneSuiteRegistrationLink()' class='col-10'>
                    <button mdbBtn size='sm' type='send' data-cy='activation-link' class='m-0' color='primary' (click)='sendActivationLink();' title='Send Activation Link' mdbWavesEffect>
                        <mdb-icon *ngIf='!isSendingAccountRegistrationLink' fas icon='envelope'></mdb-icon>
                        <div *ngIf='isSendingAccountRegistrationLink' class='spinner-border spinner-border-sm' role='status'></div>
                        <span>{{customer.emailAddress}}</span>
                    </button>
                </dd>
            </dl>

            <dl class='row' *ngIf='customer'>
                <dt class='col-2'>Number: </dt>
                <dd data-cy='customer-number' class='col-10'>{{customer.number}}</dd>

                <dt class='col-2'>Name: </dt>
                <dd data-cy='customer-name' class='col-10'>{{customer.name}}</dd>

                <dt class='col-2'>Email: </dt>
                <dd data-cy='customer-email' class='col-10'>{{customer.emailAddress}}</dd>

                <dt class='col-2'>Balance: </dt>
                <dd data-cy='customer-balance' class='col-10'>{{customer.creditBalance | currency}}</dd>

                <dt class='col-2'>Credit Limit: </dt>
                <dd data-cy='customer-credit-limit' class='col-10'>{{customer.creditLimit | currency}}</dd>

                <dt class='col-2'>Currency: </dt>
                <dd data-cy='customer-currency' class='col-10'>{{customer.currency}}</dd>
            </dl>
        </section>
    </mdb-tab>

    <mdb-tab heading='Billing Address'>
        <dl class='row p-2'>
            <dt class='col-2'>Line 1: </dt>
            <dd data-cy='billing-address-line1' class='col-10'>{{customer.billingAddress.line1}}</dd>

            <dt class='col-2'>City: </dt>
            <dd data-cy='billing-address-city' class='col-10'>{{customer.billingAddress.city}}</dd>

            <dt class='col-2'>Postal/Zip: </dt>
            <dd data-cy='billing-address-postal' class='col-10'>{{customer.billingAddress.postalCode}}</dd>

            <dt class='col-2'>Region: </dt>
            <dd data-cy='billing-address-region' class='col-10'>{{customer.billingAddress.region}}</dd>

            <dt class='col-2'>Country: </dt>
            <dd data-cy='billing-address-country' class='col-10'>{{customer.billingAddress.country}}</dd>

            <dt class='col-2'>Phone: </dt>
            <dd data-cy='billing-address-phone' class='col-10'>{{customer.billingAddress.phoneNumber}}</dd>
        </dl>
    </mdb-tab>

    <mdb-tab heading='Shipping Address - {{shipId}}' *ngFor='let shipId of objectKeys(customer.shippingAddresses); let i = index;' tabOrder='{{i + 2}}'>
        <dl class='row p-2'>
            <dt class='col-2'>Line 1: </dt>
            <dd class='col-10'>{{customer.shippingAddresses[shipId].line1}}</dd>

            <dt class='col-2'>City: </dt>
            <dd class='col-10'>{{customer.shippingAddresses[shipId].city}}</dd>

            <dt class='col-2'>Postal/Zip: </dt>
            <dd class='col-10'>{{customer.shippingAddresses[shipId].postalCode}}</dd>

            <dt class='col-2'>Region: </dt>
            <dd class='col-10'>{{customer.shippingAddresses[shipId].region}}</dd>

            <dt class='col-2'>Country: </dt>
            <dd class='col-10'>{{customer.shippingAddresses[shipId].country}}</dd>

            <dt class='col-2'>Phone: </dt>
            <dd class='col-10'>{{customer.shippingAddresses[shipId].phoneNumber}}</dd>
        </dl>
    </mdb-tab>

    <mdb-tab heading='Orders' tabOrder='tabs.length + 2'>
        <form class='form-inline'>
            <div class='md-form'>
                <input mdbInput #orderFilter type='search' class='form-control' required />
                <label for='order-search'>Filter orders</label>
            </div>

            <button mdbBtn type='search' data-cy='order-filter-button' color='primary' (click)='applyOrderFilter(orderFilter.value);' [disabled]='!orderFilter.value' title='Filter Orders' mdbWavesEffect>
                <mdb-icon *ngIf='!isLoadingOrders' fas icon='search'></mdb-icon>
                <div *ngIf='isLoadingOrders' class='spinner-border spinner-border-sm' role='status'></div>
            </button>

            <button mdbBtn type='reset' (click)='resetOrderFilter();' title='Reset Order Filter' class='btn-danger' mdbWavesEffect>
                <strong>Clear</strong>
            </button>
        </form>

        <section *ngIf='orders'>
            <table mdbTable data-cy='order-listing' hover='true' small='true'>
                <thead class="sticky-top">
                    <tr>
                        <th (click)='applyOrderSort("number")' data-cy='order-number-sort'>Order # <mdb-icon fas icon='sort'></mdb-icon></th>
                        <th (click)='applyOrderSort("orderDate")' data-cy='order-date-sort'>Date <mdb-icon fas icon='sort'></mdb-icon></th>
                        <th>Status</th>
                        <th>Subtotal</th>
                        <th>Shipping</th>
                        <th>Discount</th>
                        <th>Tax</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr mdbTableCol *ngFor='let order of orders'>
                        <td>{{order.number}}</td>
                        <td>{{order.orderDate | date}}</td>
                        <td>{{order.status}}</td>
                        <td>{{order.totals.subtotal | currency}}</td>
                        <td>{{order.totals.shipping | currency}}</td>
                        <td>{{order.totals.discount | currency}}</td>
                        <td>{{order.totals.tax | currency}}</td>
                        <td>{{order.totals.total | currency}}</td>
                        <td>
                            <button mdbBtn size='sm' class='view-order-details m-0 mr-1' color='primary' (click)='openOrderDetailsDialog(order)' title='View Order Details'>
                                <mdb-icon fas icon='info'></mdb-icon>
                            </button>

                            <button mdbBtn size='sm' class='print-order m-0' color='primary' (click)='fetchOrderPdf(order)' title='Print Order'>
                                <mdb-icon *ngIf='!isLoadingPdf(order)' fas icon='file-pdf'></mdb-icon>
                                <div *ngIf='isLoadingPdf(order)' class='spinner-border spinner-border-sm' role='status'></div>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot *ngIf='orderPages.length > 1'>
                    <tr>
                        <td colspan='9'>
                            <nav>
                                <ul class='pagination pg-blue justify-content-center'>
                                    <li *ngFor='let page of orderPages' class='page-item {{hasActiveOrderClass(page)}}'>
                                        <a (click)='setOrderPage(page)' class='page-link' mdbWavesEffect>{{page}}</a>
                                    </li>
                                </ul>
                            </nav>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </section>
    </mdb-tab>

    <mdb-tab heading='Invoices' tabOrder='tabs.length + 2'>
        <form class='form-inline'>
            <div class='md-form'>
                <input mdbInput #invoiceFilter type='search' class='form-control' required />
                <label for='invoice-search'>Filter invoices</label>
            </div>

            <button mdbBtn type='search' data-cy='invoice-filter-button' color='primary' (click)='applyInvoiceFilter(invoiceFilter.value);' [disabled]='!invoiceFilter.value' title='Filter Invoices' mdbWavesEffect>
                <mdb-icon *ngIf='!isLoadingInvoices' fas icon='search'></mdb-icon>
                <div *ngIf='isLoadingInvoices' class='spinner-border spinner-border-sm' role='status'></div>
            </button>

            <button mdbBtn type='reset' (click)='resetInvoiceFilter();' title='Reset Invoice Filter' class='btn-danger' mdbWavesEffect>
                <strong>Clear</strong>
            </button>
        </form>

        <section *ngIf='invoices'>
            <table mdbTable data-cy='invoice-listing' hover='true' small='true'>
                <thead class="sticky-top">
                    <tr>
                        <th (click)='applyInvoiceSort("number")' data-cy='invoice-number-sort'>Invoice # <mdb-icon fas icon='sort'></mdb-icon></th>
                        <th (click)='applyInvoiceSort("invoiceDate")' data-cy='invoice-date-sort'>Date <mdb-icon fas icon='sort'></mdb-icon></th>
                        <th>Status</th>
                        <th>Subtotal</th>
                        <th>Shipping</th>
                        <th>Discount</th>
                        <th>Tax</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr mdbTableCol *ngFor='let invoice of invoices'>
                        <td>{{invoice.number}}</td>
                        <td>{{invoice.invoiceDate | date}}</td>
                        <td>{{invoice.status}}</td>
                        <td>{{invoice.totals.subtotal | currency}}</td>
                        <td>{{invoice.totals.shipping | currency}}</td>
                        <td>{{invoice.totals.discount | currency}}</td>
                        <td>{{invoice.totals.tax | currency}}</td>
                        <td>{{invoice.totals.total | currency}}</td>
                        <td>
                            <button mdbBtn size='sm' class='view-invoice-details m-0 mr-1' color='primary' (click)='openInvoiceDetailsDialog(invoice)' title='View Invoice Details'>
                                <mdb-icon fas icon='info'></mdb-icon>
                            </button>

                            <button mdbBtn size='sm' class='print-invoice m-0' color='primary' (click)='fetchInvoicePdf(invoice)' title='Print Invoice'>
                                <mdb-icon *ngIf='!isLoadingPdf(invoice)' fas icon='file-pdf'></mdb-icon>
                                <div *ngIf='isLoadingPdf(invoice)' class='spinner-border spinner-border-sm' role='status'></div>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot *ngIf='invoicePages.length > 1'>
                    <tr>
                        <td colspan='9'>
                            <nav>
                                <ul class='pagination pg-blue justify-content-center'>
                                    <li *ngFor='let page of invoicePages' class='page-item {{hasActiveInvoiceClass(page)}}'>
                                        <a (click)='setInvoicePage(page)' class='page-link' mdbWavesEffect>{{page}}</a>
                                    </li>
                                </ul>
                            </nav>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </section>
    </mdb-tab>
</mdb-tabset>
