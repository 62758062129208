import { Injectable } from '@angular/core'
import { of } from 'rxjs'
import { HttpService } from './http.service'
import { UserProfileService } from '../services/user-profile.service'

@Injectable()
export class LogService {

    public constructor(private httpService: HttpService, private userProfile: UserProfileService) { }

    public fetchEvents() {
        return this.httpService.get('logs/channel=zeckoSoftwareApi&from=-1d&until=now&size=100')
    }
}
