import { Injectable } from '@angular/core'
import * as _ from 'lodash'
import * as moment from 'moment'

@Injectable()
export class UserProfileService {
    public constructor() {}

    public hasProfile() {
        return localStorage.getItem('profile') !== null
    }

    public getId() {
        return _.get(this.getProfile(), 'id', null)
    }

    public getEmail() {
        return _.get(this.getProfile(), 'email', null)
    }

    public getDefaultApp() {
        return _.get(this.getProfile(), 'defaultApp', null)
    }

    public getApiKey() {
        return _.get(this.getProfile(), 'apiKey', null)
    }

    public getClientId() {
        return _.get(this.getProfile(), 'clientId', '')
    }

    public getApps() {
        return _.get(this.getProfile(), 'acl.apps', null)
    }

    public getErpSettings() {
        return _.get(this.getProfile(), 'erpSettings', null)
    }

    public getRole() {
        return _.get(this.getProfile(), 'acl', { role: 'guest' })
    }

    public getCustomerNumber() {
        return _.get(this.getProfile(), 'num', null)
    }

    public isActiveTrial(name) {
        const app = this.getAppByName(name)

        return !_.isUndefined(app) && moment().isBefore(app.trial)
    }

    public isTrialAccount(name) {
        return this.getAccountStatus(name) === 'trial'
    }

    public isActiveAccount(name) {
        return this.getAccountStatus(name) === 'active'
    }

    public getTrialExpiry(name) {
        return this.getAppByName(name).trial
    }

    private getAccountStatus(name) {
        const app = this.getAppByName(name)

        return !_.isUndefined(app) && _.isNull(app.trial) ? 'active' : 'trial'
    }

    private getAppByName(name) {
        const currentAppName = name.replace('One', '')
        const appKey = currentAppName.charAt(0).toLowerCase() + currentAppName.slice(1)
        const apps = this.getApps()

        return apps ? apps[appKey] : name
    }

    public isAccountAppEnabled() {
        return this.isAppEnabled('account')
    }

    public isStockAppEnabled() {
        return this.isAppEnabled('stock')
    }

    public isShippingAppEnabled() {
        return this.isAppEnabled('shipping')
    }

    public isWarehouseTransferAppEnabled() {
        return this.isAppEnabled('warehouseTransfer')
    }

    private isAppEnabled(name) {
        const app = this.getApp(name)

        return !_.isNull(app) ? app.active : false
    }

    public getApp(name) {
        const apps = this.getApps()

        return !_.isNull(apps) && _.has(apps, name) ? apps[name] : null
    }

    public getShippingSettings() {
        return _.get(this.getProfile(), 'appSettings.shipping', [])
    }

    public getWarehouseTransferSettings() {
        return _.get(this.getProfile(), 'appSettings.warehouseTransfer', [])
    }

    public clear() {
        localStorage.removeItem('profile')
    }

    public setProfile(profile) {
        localStorage.setItem('profile', JSON.stringify(profile))
    }

    private getProfile() {
        return JSON.parse(localStorage.getItem('profile'))
    }
}
