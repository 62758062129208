import { Injectable } from '@angular/core'
import { forkJoin, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import * as _ from 'lodash'
import { ApiService } from '../services/api.service'
import { HttpService } from './http.service'
import { ShippingOrderTransformerService } from './shipping-order-transformer.service'
import { ToastService } from 'ng-uikit-pro-standard'
import { UserProfileService } from './user-profile.service'

@Injectable()
export class ShippingService {
    public constructor(
        private httpService: HttpService,
        private shippingOrderTransformerService: ShippingOrderTransformerService,
        private userProfile: UserProfileService,
        private api: ApiService,
        private toast: ToastService
    ) { }

    public fetchCarriers(): Observable<any> {
        return this.httpService.get('shipping/carriers')
    }

    public fetchRates(carriers, order): Observable<any> {
        order = this.transformOrder(order)
        return this.httpService.multiRequest(this.buildRateRequests(carriers, order))
    }

    private buildRateRequests(carriers, order) {
        const self = this
        return _.map(carriers, (carrier) => {
            return {
                url: 'shipping/carriers/' + carrier + '/rates',
                body: order,
                successCallback: _.curryRight(self.addCarrierToRateResponse)(carrier),
            }
        })
    }

    private addCarrierToRateResponse(res, carrier) {
        return _.map(res, (item) => {
            return _.merge({ carrier, id: carrier + '_' + item.code }, item)
        })
    }

    public fetchTrackingInfo(carrier, trackingNumber): Observable<any> {
        return this.httpService.get('shipping/carriers/' + carrier + '/shipments/' + trackingNumber)
    }

    public fetchShipment(orderId) {
        return this.httpService.get('shipping/orders/' + orderId + '/shipments')
    }

    public processShippingLabel(shipment) {
        const shippingSettings = this.userProfile.getShippingSettings()

        if (!_.isUndefined(shippingSettings.autoPrint && shippingSettings.autoPrint)) {
            this.printShippingLabel(shipment)
        } else {
            this.downloadShippingLabel(shipment)
        }
    }

    private printShippingLabel(shipment: any) {
        const labelFormat = shipment.packages[0].labelFormat

        let url
        let body
        let type

        if (labelFormat === 'pdf') {
            type = 'application/pdf'
            body = new Uint8Array(
                atob(shipment.packages[0].labelImage)
                    .split('')
                    .map((char) => char.charCodeAt(0))
            )
        } else {
            type = 'text/html'
            url = 'data:image/' + labelFormat + ';base64,' + shipment.packages[0].labelImage
            body = '<html><body><img src="' + url + '"></body></html>'
        }

        const iframe = document.createElement('iframe')
        const contents = new Blob([body], { type })
        iframe.src = URL.createObjectURL(contents)
        document.body.appendChild(iframe)
        iframe.style.visibility = 'hidden'
        iframe.contentWindow.print()
    }

    private downloadShippingLabel(shipment: any) {
        const labelFormat = shipment.packages[0].labelFormat
        const encoding = labelFormat === 'gif' ? 'image/gif' : 'application/pdf'
        const linkSource = 'data:' + encoding + ';base64,' + shipment.packages[0].labelImage

        const downloadLink = document.createElement('a')
        const fileName = shipment.trackingNumber + '.' + labelFormat

        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
    }

    public createShipment(carrier, order, shippingOptions) {
        order = this.transformOrder(order)
        order.shippingOptions = shippingOptions

        return this.httpService.post('shipping/carriers/' + carrier + '/shipments', order)
    }

    public voidShipment(carrier: string, order: any) {
        this.httpService.post('shipping/carriers/' + carrier + '/shipments/void', order).subscribe(
            (res) => {
                order.shipments = []
                order.hold = false
                this.api.updateOrder(order).subscribe(() => {
                    this.showSuccessToast('Shipment voided')
                })
            },
            (err) => {
                this.showErrorToast('Unable to void shipment')
            }
        )
    }

    private transformOrder(order) {
        order = this.shippingOrderTransformerService.transform(order)

        const settings = this.userProfile.getShippingSettings()
        order.dimensionUnit = settings.dimensionUnit
        order.weightUnit = settings.weightUnit

        return order
    }

    private showErrorToast(message) {
        this.toast.error(message, '', { opacity: 1 })
    }

    private showSuccessToast(message: string) {
        this.toast.success(message, '', { opacity: 1 })
    }
}
