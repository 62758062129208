import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Subject, Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import * as _ from 'lodash'
import { ApiService } from '../services/api.service'
import { CacheService } from '../services/cache-service';

const CACHE_KEY = 'product-categories'

export interface ProductCategory {
    readonly number: string
    readonly description: string
}

@Component({
    selector: 'app-product-categories',
    templateUrl: './product-categories.component.html',
})
export class ProductCategoriesComponent implements OnInit {
    @Input() items: any
    @Input() category: any
    @Output() selectedCategory = new EventEmitter()

    public searchText = new Subject()
    public filteredOptions: Observable<any[]>

    public categories: ProductCategory[]

    public constructor(private apiService: ApiService, private cache: CacheService) {}

    ngOnInit() {
        this.fetchCategories()
    }

    private fetchCategories(): void {
        const categoryCache = this.cache.getCacheItem(CACHE_KEY)

        if (Object.keys(categoryCache.data).length === 0) {
            this.apiService.fetchProductCategories().subscribe((res) => {
                this.categories = _.sortBy(res.items, 'description')

                this.cache.setCacheItem(CACHE_KEY, this.categories)
                this.setFilters()
            })
        } else {
            this.categories = categoryCache.data
            this.setFilters()
        }
    }

    private setFilters() {
        this.filteredOptions = this.searchText.pipe(
            startWith(''),
            map((description: string) => this.filter(description))
        )
    }

    public filter(value: string): any[] {
        const filterValue = value.toLowerCase()
        return this.categories.filter((option: any) => option.description.toLowerCase().includes(filterValue))
    }

    public categorySelected() {
        let newCategory: any
        this.categories.map((category: any) => {
            if (category.number === this.category) {
                newCategory = category
            }
        })

        this.selectedCategory.emit(newCategory)
    }
}
