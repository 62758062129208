import { Component, Input } from '@angular/core';
import { AppDataService } from '../../services/app-data.service';
import { ShippingService } from '../../services/shipping.service';

@Component({
    selector: 'app-shipping-tracking',
    templateUrl: './tracking.component.html',
})
export class ShippingTrackingComponent {
    @Input() trackingInfo: any;
    @Input() errorMessage: string;

    public displayedColumns: string[] = ['description', 'status', 'dateTime', 'signatory', 'location'];

    public constructor(public appDataService: AppDataService, private shippingService: ShippingService) {
        this.setVars(null, null);
    }

    public fetchTrackingInfo(carrier, trackingNumber) {
        this.shippingService.fetchTrackingInfo(carrier, trackingNumber).subscribe(
            (trackingInfo) => {
                this.setVars(trackingInfo[0], null);
            },
            (err) => {
                this.setVars(null, 'Problem retrieving tracking information.');
            }
        );
    }

    private setVars(trackingInfo, errorMessage) {
        this.trackingInfo = trackingInfo;
        this.errorMessage = errorMessage;
    }
}
