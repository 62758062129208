<section id='dashboard' class='container'>
    <div class='row'>
        <mdb-card class='col mr-1'>
            <mdb-card-header class='text-center'><h4 data-cy='customers-by-billing-header'>Customers by Billing</h4></mdb-card-header>
            <div *ngIf='loadingCustomersByBilling'>Loading data...</div>
            <div *ngIf='errorCustomersByBilling' class='text-danger'>{{errorCustomersByBilling}}</div>
            <ul data-cy='customers-by-billing-list' class='list-group list-group-flush'>
                <li *ngFor='let data of customersByBilling' class='list-group-item d-flex justify-content-between align-items-center'>
                    <div>{{data.name}}<br/><small>#{{data.number}}</small></div> <mdb-badge success='true'>{{data.sales|currency}}</mdb-badge>
                </li>
            </ul>
        </mdb-card>

        <mdb-card class='col ml-1'>
            <mdb-card-header class='text-center'><h4 data-cy='customers-by-due-header'>Customers by Due</h4></mdb-card-header>
            <div *ngIf='loadingCustomersByDue'>Loading data...</div>
            <div *ngIf='errorCustomersByDue' class='text-danger'>{{errorCustomersByDue}}</div>
            <ul data-cy='customers-by-due-list' class='list-group list-group-flush'>
                <li *ngFor='let data of customersByDue' class='list-group-item d-flex justify-content-between align-items-center'>
                    <div>{{data.name}}<br/><small>#{{data.number}}</small></div> <mdb-badge warning='true'>{{data.creditBalance|currency}}</mdb-badge>
                </li>
            </ul>
        </mdb-card>
    </div>
</section>
