import { Component, OnInit } from '@angular/core';
import { AppDataService } from '../services/app-data.service';
import { ReportService } from '../services/report.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

    public customersByBilling;
    public customersByDue;
    public errorCustomersByBilling;
    public errorCustomersByDue;
    public loadingCustomersByBilling;
    public loadingCustomersByDue;

    public constructor(public appData: AppDataService, private reportService: ReportService) {
        this.appData.currentAppName = 'OneDashboard';
    }

    public ngOnInit() {
        this.loadCustomersByBilling();
        this.loadCustomersByDue();
    }

    private loadCustomersByBilling() {
        this.loadingCustomersByBilling = true;
        this.reportService.fetchCustomersByBilling().subscribe(
            data => {
                this.loadingCustomersByBilling = false;
                this.customersByBilling = data;
            },
            err => {
                this.loadingCustomersByBilling = false;
                this.errorCustomersByBilling = 'Error retrieving customer by billing report: ' + err.error;
            }
        );
    }

    private loadCustomersByDue() {
        this.loadingCustomersByDue = true;
        this.reportService.fetchCustomersByDue().subscribe(
            data => {
                this.loadingCustomersByDue = false;
                this.customersByDue = data;
            },
            err => {
                this.loadingCustomersByDue = false;
                this.errorCustomersByDue = 'Error retrieving customer by due report: ' + err.error;
            }
        );
    }
}
