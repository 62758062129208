import { Component, OnInit } from '@angular/core'
import { LogService } from '../services/log.service'
import { MDBModalRef } from 'ng-uikit-pro-standard'

@Component({
    selector: 'app-log-dialog',
    templateUrl: './log-dialog.component.html'
})
export class LogDialogComponent implements OnInit {

    public errorMessage
    public events
    public retrievingDetails

    public constructor(private log: LogService, public modalRef: MDBModalRef) { }

    ngOnInit(): void {
        this.retrievingDetails = true
        this.log.fetchEvents().subscribe(
            res => {
                this.retrievingDetails = false
                this.events = this.parseEvents(res)
            },
            err => {
                this.retrievingDetails = false
                this.errorMessage = 'Error retrieving events: ' + err.error
            }
        );
    }

    private parseEvents(res) {
        const events = []
        for (const event of res.events) {
            const context = event.event.json.context
            const message = context.message ? context.message : context.content

            if (message && message !== 'null') {
                events.push({
                    requestedUri: context.requestedUri,
                    message
                })
            }
        }

        return events
    }
}
