import * as _ from 'lodash'

const enum SalesOrderStatus {
    Closed = 'C',
    Deposit = 'D',
    Open = 'O',
    Processed = 'P',
    Shipped = 'S'
}

export class Customer {

    public constructor(data = {}) {
        Object.assign(this, data)
    }

    public id = ''
    public number = ''
    public email = ''
    public name = ''
    public firstName = ''
    public lastName = ''
    public userDefinedFields: Array<object>
}

export class Address {

    public constructor(data = {}) {
        Object.assign(this, data)
    }

    public name = ''
    public postalZip = ''
    public id = ''
    public line1 = ''
    public line2 = ''
    public line3 = ''
    public line4 = ''
    public city = ''
    public postalCode = ''
    public region = ''
    public country = ''
    public phoneNumber = ''
    public email = ''
}

export class LineItem {

    public constructor(data = {}) {
        Object.assign(this, data)
    }

    public partNumber = ''
    public description = ''
    public warehouse = ''
    public unitOfMeasure = ''
    public quantity: number
    public shipQuantity: number
    public price: number
    public weight: number
    public length: number
    public width: number
    public height: number
    public userDefinedFields: Array<object>
}

export class SalesOrder {

    public number = ''
    public poNumber = ''
    public referenceNumber = ''
    public fob = ''
    public shipVia = ''
    public orderDate = ''
    public status = ''
    public hold: boolean
    public terms = ''
    public totals: object = {}
    public customer: Customer = new Customer()
    public billingAddress: Address = new Address()
    public shippingAddress: Address = new Address()
    public items: Array<LineItem> = []
    public shipments: Array<any> = []
    public userDefinedFields: Array<object> = []
    public boxes: Array<any> = []
    public shippingOrigin: object

    private statusNames = {
        C: 'Closed',
        O: 'Open',
        S: 'Shipped',
        P: 'Processed',
        L: 'Deposit'
    }

    public constructor(data = {}) {
        Object.assign(this, data)

        this.customer = new Customer(this.customer)
        this.billingAddress = new Address(this.billingAddress)
        this.shippingAddress = new Address(this.shippingAddress)
        this.items = this.items.map(item => new LineItem(item));
    }

    public isLoaded(): boolean {
        return this.number.length > 0
    }

    public reset() {
        this.number = ''
        this.items = []
        this.shipments = []
        this.boxes = []
        this.shippingAddress = new Address()
    }

    public getCustomerNumber(): string {
        return !_.isUndefined(this.customer) ? this.customer.number : null
    }

    public getStatusName(): string {
        return this.statusNames[this.status]
    }

    public getLineItemCount(): number {
        return this.items.length
    }

    public addLineItem(newItem) {
        this.items.push(_.clone(newItem))
    }

    public removeLineItem(index) {
        this.items.splice(index, 1)
    }

    public buildDefaultLineItem(): object {
        return {
            partNumber: '',
            quantity: 0,
            weight: 0,
            length: 0,
            width: 0,
            height: 0,
        }
    }

    public getTotalQuantity(): number {
        return this.getSummedValue('quantity')
    }

    public getTotalWeight(): number {
        return this.getSummedValue('weight')
    }

    public getMaxLength(): number {
        return this.getMaxValue('length')
    }

    public getMaxWidth(): number {
        return this.getMaxValue('width')
    }

    public getMaxHeight(): number {
        return this.getMaxValue('height')
    }

    private getSummedValue(prop): number {
        return _.reduce(
            this.items,
            (sum, item) => {
                return sum + item[prop]
            },
            0
        )
    }

    private getMaxValue(prop): number {
        return _.reduce(
            this.items,
            (max, item) => {
                return item[prop] > max ? item[prop] : max
            },
            0
        )
    }

    public hasTrackingNumber(): boolean {
        return this.shipments.length && this.shipments[0].trackingNumber ? true : false
    }

    public isShipped(): boolean {
        return this.status === SalesOrderStatus.Shipped || this.hasTrackingNumber()
    }

    public isProcessed(): boolean {
        return this.status === SalesOrderStatus.Processed
    }

    public buildShippingOrder(rate, service, trackingNumber, usingFlatRateShipping): object {
        const date = new Date()
        const shipDate = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate()
        const carrier = _.truncate(service, { length: 20, omission: '' })

        const shippingOrder = {
            number: this.number,
            hold: this.hold,
            status: this.status,
            totals: {},
            shipments: [
                {
                    carrier,
                    service,
                    trackingNumber,
                    shipDate,
                },
            ]
        }

        if (trackingNumber) {
            shippingOrder.status = SalesOrderStatus.Shipped
        }

        if (!usingFlatRateShipping) {
            shippingOrder.totals = { shipping: rate }
        }

        return shippingOrder
    }
}
