import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService, NbAuthSimpleToken, NbTokenService } from '@nebular/auth';
import { UserProfileService } from '../services/user-profile.service';
import * as _ from 'lodash';
import initHelpHero from 'helphero';

@Injectable()
export class AuthService {

    public user: any;

    public constructor(
        private router: Router,
        public authService: NbAuthService,
        private tokenService: NbTokenService,
        private userProfileService: UserProfileService
    ) {
        this.user = {};

        this.authService.onTokenChange()
            .subscribe((token: NbAuthSimpleToken) => {
                if (token.isValid()) {
                    const profile = token.getValue();
                    this.user = _.isString(profile) ? JSON.parse(profile) : profile;
                    this.userProfileService.setProfile(this.user);

                    // const hlp = initHelpHero('uSHNvVFWHbU');
                    // hlp.identify(this.user.email);
                }
            });
    }

    public login() {
        this.router.navigate(['auth/login']);
    }

    public logout() {
        this.user = {};
        this.tokenService.clear();
        this.userProfileService.clear();
        this.router.navigateByUrl('');
    }

    public register() {
        this.router.navigate(['auth/register']);
    }

    public authenticated() {
        return this.userProfileService.getApiKey();
    }
}
