<app-company></app-company>

<section class='z-depth-2 p-3 m-3'>
    <form class='form-inline'>
        <div class='md-form'>
            <input mdbInput #productSearch data-cy='product-search'[(ngModel)]='searchString' name='search' type='search' class='form-control' required />
            <label>Part Number / UPC</label>
        </div>

        <button mdbBtn data-cy='product-search-button' type='search' color='primary' (click)='fetchProduct(productSearch.value);' [disabled]='!productSearch.value' title='Retrieve Stock' class='search-button' mdbWavesEffect>
            <mdb-icon fas *ngIf='!isLoadingProduct' icon='search'></mdb-icon>
            <div *ngIf='isLoadingProduct' class='spinner-border spinner-border-sm' role='status'></div>
        </button>
    </form>

    <mdb-select-2 data-cy='company-select' placeholder='Select Vendor' [(ngModel)]='selectedVendor' (ngModelChange)="getVendorProducts()" >
        <mdb-select-option *ngFor='let vendor of vendors' [value]='vendor.number' >{{ vendor.name }} </mdb-select-option>
    </mdb-select-2>
</section>

<section class='z-depth-2 p-3 m-3' *ngIf='product'>
    <h4 data-cy='stock-header' class='h4-responsive'>Stock for item {{product.number}}</h4>

    <dl class='row'>
        <dt class='col-2'>Description:</dt>
        <dd data-cy='product-description' class='col-10'>{{product.description}}</dd>

        <dt class='col-2'>Price:</dt>
        <dd data-cy='product-price' class='col-10'>{{product.basePrice | currency}}/{{product.unitOfMeasure}}</dd>

        <dt class='col-2'>YTD Sales:</dt>
        <dd data-cy='product-ytd-sales' class='col-10'>{{product.yearToDateSales | currency}}</dd>

        <dt class='col-2'>LYTD Sales:</dt>
        <dd data-cy='product-lytd-sales' class='col-10'>{{product.lastYearSales | currency}}</dd>
    </dl>

    <table data-cy='stock-table' mdbTable hover='true' small='true'>
        <thead class='sticky-top'>
            <tr>
                <th>Warehouse</th>
                <th>Available</th>
                <th>On Hand</th>
                <th>Committed</th>
                <th>On Order</th>
                <th>On Backorder</th>
            </tr>
        </thead>

        <tbody>
            <tr mdbTableCol *ngFor='let warehouse of product.warehouses'>
                <td>{{warehouse.warehouse}}</td>
                <td>{{warehouse.quantityAvailable}}</td>
                <td>{{warehouse.quantityOnHand}}</td>
                <td>{{warehouse.quantityCommitted}}</td>
                <td>{{warehouse.quantityOnOrder}}</td>
                <td>{{warehouse.quantityBackordered}}</td>
            </tr>
        </tbody>

        <tfoot class='grey lighten-2'>
            <tr>
                <td></td>
                <td>{{product.available}}</td>
                <td>{{product.onHand}}</td>
                <td>{{product.committed}}</td>
                <td>{{product.onOrder}}</td>
                <td>{{product.onBackorder}}</td>
            </tr>
        </tfoot>
    </table>

    <div class='card mt-2'>
        <div class='card-body'>
            <h5 class='card-title'>Alternates</h5>

            <table data-cy='alt-stock-table' mdbTable hover='true' small='true' *ngIf='product.alternates && product.alternates.length > 0'>
                <thead class='sticky-top'>
                    <tr class='table-light'>
                        <th>Part #</th>
                        <th>Available</th>
                        <th>On Hand</th>
                        <th>Committed</th>
                        <th>On Order</th>
                        <th>On Backorder</th>
                    </tr>
                </thead>

                <tbody>
                    <tr class='table-light' mdbTableCol *ngFor='let alt of product.alternates'>
                        <td>{{alt.number}}</td>
                        <td>{{alt.available}}</td>
                        <td>{{alt.onHand}}</td>
                        <td>{{alt.committed}}</td>
                        <td>{{alt.onOrder}}</td>
                        <td>{{alt.onBackorder}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>

<section *ngIf="products && !product">
    <h5 class='h4-responsive'>Item details for {{ vendorDetails.name }}</h5>
    <div class='card mt-2' *ngFor='let product of products'>
        <div class='card-body'>
            <dl class='row'>
                <dt class='col-2'>Part Number:</dt>
                <dd data-cy='product-part-number' class='col-10'>{{ product.number }}</dd>

                <dt class='col-2'>Description:</dt>
                <dd data-cy='product-description' class='col-10'>{{ product.description }}</dd>

                <dt class='col-2'>Price:</dt>
                <dd data-cy='product-price' class='col-10'>{{product.basePrice | currency}}/{{product.unitOfMeasure}}</dd>

                <dt class='col-2'>YTD Sales:</dt>
                <dd data-cy='product-ytd-sales' class='col-10'>{{product.yearToDateSales | currency}}</dd>

                <dt class='col-2'>LYTD Sales:</dt>
                <dd data-cy='product-lytd-sales' class='col-10'>{{product.lastYearSales | currency}}</dd>
            </dl>

            <table data-cy='stock-table' mdbTable hover='true' small='true'>
                <thead class='sticky-top'>
                    <tr>
                        <th>Warehouse</th>
                        <th>Available</th>
                        <th>On Hand</th>
                        <th>Committed</th>
                        <th>On Order</th>
                        <th>On Backorder</th>
                    </tr>
                </thead>

                <tbody>
                    <tr mdbTableCol *ngFor='let warehouse of product.warehouses'>
                        <td>{{warehouse.warehouse}}</td>
                        <td>{{warehouse.quantityAvailable}}</td>
                        <td>{{warehouse.quantityOnHand}}</td>
                        <td>{{warehouse.quantityCommitted}}</td>
                        <td>{{warehouse.quantityOnOrder}}</td>
                        <td>{{warehouse.quantityBackordered}}</td>
                    </tr>
                </tbody>

                <tfoot class='grey lighten-2'>
                    <tr>
                        <td></td>
                        <td>{{product.available}}</td>
                        <td>{{product.onHand}}</td>
                        <td>{{product.committed}}</td>
                        <td>{{product.onOrder}}</td>
                        <td>{{product.onBackorder}}</td>
                    </tr>
                </tfoot>
            </table>

            <div class='mt-2' *ngIf='product.alternates && product.alternates.length > 0'>
                <h5>Alternates</h5>
                <table data-cy='alt-stock-table' mdbTable hover='true' small='true' >
                    <thead class='sticky-top'>
                        <tr class='table-light'>
                            <th>Part #</th>
                            <th>Available</th>
                            <th>On Hand</th>
                            <th>Committed</th>
                            <th>On Order</th>
                            <th>On Backorder</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class='table-light' mdbTableCol *ngFor='let alt of product.alternates'>
                            <td>{{alt.number}}</td>
                            <td>{{alt.available}}</td>
                            <td>{{alt.onHand}}</td>
                            <td>{{alt.committed}}</td>
                            <td>{{alt.onOrder}}</td>
                            <td>{{alt.onBackorder}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>
