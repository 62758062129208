import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class StockPresenterService {

    public constructor() { }

    public setStockTotals(product) {
        let onHand = 0;
        let committed = 0;
        let available = 0;
        let onOrder = 0;
        let onBackorder = 0;

        _.forEach(product.warehouses, (warehouse) => {
            warehouse.quantityAvailable = warehouse.quantityOnHand - warehouse.quantityCommitted;

            onHand += warehouse.quantityOnHand;
            committed += warehouse.quantityCommitted;
            available += warehouse.quantityAvailable;
            onOrder += warehouse.quantityOnOrder;
            onBackorder += warehouse.quantityBackordered;
        });

        product.onHand = onHand;
        product.committed = committed;
        product.available = available;
        product.onOrder = onOrder;
        product.onBackorder = onBackorder;

        return product;
    }
}
