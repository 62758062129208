<div data-cy='modal'>
    <div class='modal-header'>
        <button type='button' class='close pull-right' aria-label='Close' (click)='modalRef.hide()'>
            <span aria-hidden='true'>×</span>
        </button>
        <h4 data-cy='modal-header' class='modal-title w-100'>Invoice # {{invoice.number}}</h4>
    </div>
    <div *ngIf='!retrievingDetails && !errorMessage' data-cy='modal-body' class='modal-body mat-elevation-z8'>
        <mdb-tabset [buttonClass]='"classic-tabs tabs-primary"' [contentClass]='"card"' class='classic-tabs'>
            <mdb-tab heading='Details'>
                <table mdbTable *ngIf='invoice.items' hover='true' small='true'>
                    <thead class="sticky-top">
                        <tr>
                            <th>Part #</th>
                            <th>Description</th>
                            <th>Warehouse</th>
                            <th>UoM</th>
                            <th>Qty</th>
                            <th>Ship Qty</th>
                            <th>Weight</th>
                            <th>Price</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr mdbTableCol *ngFor='let item of invoice.items'>
                            <td>{{item.partNumber}}</td>
                            <td>{{item.description}}</td>
                            <td>{{item.warehouse}}</td>
                            <td>{{item.unitOfMeasure}}</td>
                            <td>{{item.quantity}}</td>
                            <td>{{item.shipQuantity | number}}</td>
                            <td>{{item.weight | number}}</td>
                            <td>{{item.price | currency}}</td>
                        </tr>
                    </tbody>
                </table>
            </mdb-tab>

            <mdb-tab heading='Billing Address'>
                <dl class='row p-2'>
                    <dt class='col-2'>Name: </dt>
                    <dd data-cy='invoice-billing-name' class='col-10'>{{invoice.billingAddress.name}}</dd>

                    <dt class='col-2'>Address: </dt>
                    <dd data-cy='invoice-billing-address' class='col-10'> {{invoice.billingAddress.line1}}</dd>

                    <dt class='col-2'>City: </dt>
                    <dd data-cy='invoice-billing-city' class='col-10'>{{invoice.billingAddress.city}}</dd>

                    <dt class='col-2'>Region: </dt>
                    <dd data-cy='invoice-billing-region' class='col-10'>{{invoice.billingAddress.region}}</dd>

                    <dt class='col-2'>Country: </dt>
                    <dd data-cy='invoice-billing-country' class='col-10'>{{invoice.billingAddress.country}}</dd>

                    <dt class='col-2'>Postal/Zip: </dt>
                    <dd data-cy='invoice-billing-postal' class='col-10'>{{invoice.billingAddress.postalZip}}</dd>

                    <dt class='col-2'>Phone: </dt>
                    <dd data-cy='invoice-billing-phone' class='col-10'>{{invoice.billingAddress.phoneNumber}}</dd>
                </dl>
            </mdb-tab>

            <mdb-tab heading='Shipping Address'>
                <dl class='row p-2'>
                    <dt class='col-2'>Name: <dt>
                    <dd data-cy='invoice-shipping-name' class='col-10'>{{invoice.shippingAddress.name}}</dd>

                    <dt class='col-2'>Address: <dt>
                    <dd data-cy='invoice-shipping-address' class='col-10'>{{invoice.shippingAddress.line1}}</dd>

                    <dt class='col-2'>City: <dt>
                    <dd data-cy='invoice-shipping-city' class='col-10'>{{invoice.shippingAddress.city}}</dd>

                    <dt class='col-2'>Region: <dt>
                    <dd data-cy='invoice-shipping-region' class='col-10'>{{invoice.shippingAddress.region}}</dd>

                    <dt class='col-2'>Country: <dt>
                    <dd data-cy='invoice-shipping-country' class='col-10'>{{invoice.shippingAddress.country}}</dd>

                    <dt class='col-2'>Postal/Zip: <dt>
                    <dd data-cy='invoice-shipping-postal' class='col-10'>{{invoice.shippingAddress.postalZip}}</dd>

                    <dt class='col-2'>Phone: <dt>
                    <dd data-cy='invoice-shipping-phone' class='col-10'>{{invoice.shippingAddress.phoneNumber}}</dd>
                </dl>
            </mdb-tab>
        </mdb-tabset>
    </div>
    <div class='modal-footer'>
        <div *ngIf='retrievingDetails' class='alert alert-info'>
            Retrieving details...
        </div>

        <div *ngIf='errorMessage' data-cy='modal-error' class='alert alert-danger'>
            {{errorMessage}}
        </div>

        <button mdbBtn color='primary' (click)='modalRef.hide()'>
            <mdb-icon fas icon='close'></mdb-icon> Close
        </button>
    </div>
</div>
