import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable()
export class ReportService {

    public constructor(private httpService: HttpService) { }

    public fetchCustomersByBilling() {
        return this.httpService.get('one-api/reports/customersByBilling');
    }

    public fetchCustomersByDue() {
        return this.httpService.get('one-api/reports/customersByDue');
    }
}
