import { Component, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { AppDataService } from '../../services/app-data.service'
import { ShippingCarrier } from '../../models/shipping-carrier'
import { ShippingService } from '../../services/shipping.service'
import { UserProfileService } from '../../services/user-profile.service'
import * as _ from 'lodash'

@Component({
    selector: 'app-shipping-carriers',
    templateUrl: './carriers.component.html'
})
export class ShippingCarriersComponent implements OnInit {

    public errorMessage: string

    constructor(public data: AppDataService, private shipping: ShippingService, private user: UserProfileService) {
        this.setVars([], null)
    }

    public ngOnInit() {
        this.shipping.fetchCarriers().subscribe(
            carriers => { this.setVars(carriers, null) },
            err => { this.setVars([], 'Problem retrieving shipping carriers.') }
        )
    }

    private setVars(carriers, errorMessage) {
        this.errorMessage = errorMessage
        this.data.availableCarriers = _.map(carriers, (carrier) => {
            return new ShippingCarrier(carrier)
        })
    }

    public getSelectedCarriers() {
        const selected = []
        _.forEach(this.data.availableCarriers, (carrier) => {
            if (carrier.isSelected) {
                selected.push(carrier.service)
            }
        })

        return selected
    }
}
